
body {
  background: $bg;
  direction: rtl;
  text-align: right;
  font-family: $font-family-base;
  color: $app-color;

  overflow: hidden !important;
  overflow-y: auto !important;


  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #acafc2;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #9da0b7;
  }

  font-size: $font13;

  @media(max-width: $sidebar-responsive-size) {
    font-size: 13px;
  }

  @media(max-width: 768px) {
    font-size: 12px;
  }

  a {
    color: $t-color;

    &:hover {
      color: darken($t-color, 5%);
    }
  }

  p {
    font-size: 14px;
    line-height: 25px;
  }

}

h1, h2, h3, h4, h5, h6 {
  font-family: bakh-black
}

.input-group {

  input, select, .form-control {
    border-radius: 0 $border-radius $border-radius 0;
  }

  .input-group-text {
    border-radius: $border-radius 0 0 $border-radius;
  }
}

.app-container {
  width: 85%;
  max-width: 1297px;
  margin: 0 auto;
  @media(max-width: 1350px) {
    width: 95%;
  }

  @media(max-width: 1200px) {
    width: 98%;
  }
  @media(max-width: 768px) {
    width: 92%;
  }
}

hr {
  border-top: 1px solid rgb(142 142 142 / 10%);
}


* {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(255, 255, 255, 0.34);
  }
}

._bg_b {
  background: #e9f1fe !important;
}

#panel-wrapper {


  .pagination {
    margin-bottom: 0 !important;

    li {

      @media(max-width: 768px) {
        display: none;
        &:last-child, &:first-child {
          display: block;
        }
      }

      a, span {
        padding: 14px 12px;
        margin: 4px 1.5px;
        border-radius: $border-radius-app !important;
      }

      a {

        &:hover {
          background: rgba(255, 255, 255, 0.3) !important;
        }
      }

      &.active {
        a {
          pointer-events: none !important;
          cursor: auto !important;
        }
      }
    }
  }
}


.page-title {
  .btn {
    margin-top: -9px;
    font-size: 14px;
  }

  font-size: 20px;
  font-weight: 800;

  font-family: $font-family-krub-fat;


  @media(max-width: 768px) {
    font-size: 17px;
    margin: 0;
    padding: 0;
    svg {
      height: 15px;
      width: 15px;
    }
    .btn {
      font-size: 11px !important;
    }
  }


}


.no-data {
  text-align: center;
  padding: 40px 0;
  color: $dark-3;
  font-weight: 800;
  background: rgba(107, 107, 107, 0.1);

  svg {
    height: 80px;
    width: 80px;
    fill: $dark-3;
    margin-bottom: 15px;
  }
}

///new
.user-verifications {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;

  li {
    display: inline-block;
    background: #ebe9e9 !important;
    color: #fff !important;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    text-align: center !important;
    margin: 0 3px !important;
    width: 35px !important;
    height: 35px !important;
    padding: 4px 0px !important;
    border-radius: 6px !important;

    svg {
      width: 25px;
      height: 25px;
      fill: #888;
    }
  }

  li.bg-warning {
    background: $warning !important;

    svg {
      fill: #fff;
    }
  }

  li.bg-success {
    background: $success !important;;

    svg {
      fill: #fff;
    }
  }

  li.bg-danger {
    background: $danger !important;;

    svg {
      fill: #fff;
    }
  }


}

.user-verifications.transparent {

  li {
    background: none !important;
    border: 1px solid #cecece;

    svg {
      fill: #a9a9a9;
    }
  }

  li.bg-warning {
    background: none !important;
    border-color: $warning !important;

    svg {
      fill: $warning !important;
    }
  }

  li.bg-success {
    background: none !important;
    border-color: $success !important;

    svg {
      fill: $success !important;
    }
  }

  li.bg-danger {
    background: none !important;
    border-color: $danger !important;

    svg {
      fill: $danger !important;
    }
  }

  li.verify {
    background: none !important;
    border-color: $success !important;

    svg {
      fill: $success !important;
    }
  }
}

.user-verifications-medium {
  li {
    width: 31px !important;
    height: 31px !important;
    font-size: 19px !important;
    padding: 0px 4px !important;
    border-radius: 10px !important;

    svg {
      width: 19px;
      height: 19px;
    }
  }

}

.user-verifications-small {
  li {
    width: 26px !important;
    height: 25px !important;
    line-height: 17px;
    padding: 2px 0px !important;
    border-radius: 6px !important;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.verification-confirm-box {
  i {
    font-size: 63px;
    background: #f0f0f0;
    padding: 12px;
    border-radius: 50%;
    margin-bottom: 0;
    display: inline-block;

    @media(max-width: 768px) {
      font-size: 40px;

    }
  }

  h4 {
    font-size: 17px;
    font-weight: 600;

    @media(max-width: 768px) {
      font-size: 13px;
    }
  }

  p {
    font-size: 14px;

    @media(max-width: 768px) {
      font-size: 11px;
    }
  }
}

.fCWmrQ {
  position: relative;

  .kWLpIt {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .gHFvLO {
    flex-basis: calc(33.333% - 21px);
    min-height: 220px;
    padding: 24px;
    background: rgba(64, 75, 81, 0.5);
    transition: all 0.25s ease 0s;
  }

  .khgwab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    text-align: right;
    direction: rtl;

    .cYkSIe {
      margin-left: 9px;
    }

    .simplex__currency {
      margin-right: 0;
      margin-top: 4px;
      color: white;
      font-size: 14px;
    }

    .jMBGUT {
      font-size: 12px;
      float: left;
      top: 28px;
      color: white;
      display: block;
      position: absolute;
      left: 29px;
    }

    .simplex__currency-ticker {
      display: none;
    }
  }


  .iMOanw {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 25px;

    .dwUlSD {
      flex-basis: 50%;
    }

    .dXQceg {
      margin-bottom: 8px;
      font-size: 15px;
      color: $primary;
    }

    .eCxGIJ {
      font-size: 11px;
      color: rgb(179, 180, 180);
    }
  }

  .dZbdXz {
    flex-basis: 50%;
    height: 60px;
  }

  .gTekNF {
    text-align: center;
  }

  .cgPoFt a {
    display: block;
    height: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: 12px;
    text-decoration: inherit;
  }

  .cgPoFt {
    display: inline-block;
    position: relative;
    min-width: auto;
    max-width: 432px;
    width: auto;
    height: 33px;
    line-height: 32px;
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    text-align: center;
    cursor: pointer;
    margin: 0.6rem 0.8rem;
    padding: 0px 1.2rem;
    background: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $primary;
    border-image: initial;
    border-radius: 4px;
    text-decoration: none;
    outline: 0px;
    transition: all 0.25s ease 0s;
  }

  .gTekNF span {
    max-width: 300px;
    width: 100%;
    margin: 0px;
    transition: all 0.25s ease 0s;
  }

  .gTekNF span.sell {
    border-color: #635f5e;
  }

}


// Base
.page-loader {
  background: rgba(255, 255, 255, 0.91);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;

  img {
    display: block;
    margin: 0 auto;
  }

  .page--loading & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.page-loader.show {
  display: flex;
}

.table {
  background: #fff;

}

.table th, .table td {
  border-top: 1px solid #eee;
}

.table td {
  vertical-align: middle;
  font-size: 12px;
  text-align: right;
}

.table thead th {
  vertical-align: bottom;
  text-align: right;
  font-weight: 600 !important;
  font-family: bakh-bold !important;
  background: #b6b6b614;
  border-top: none;
  padding: 9px 9px;
  font-size: 12px;
  border-bottom: 1px solid #e7e7e7;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(168 187 204 / 5%);
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f0f0f0;
}

section.main {
  min-height: 576px;
}


.main-notifications {
  ul {
    background: #3c4346;
    margin: 0 -24px;
    padding: 5px !important;
  }

  .nav-link {
    background: none;
    padding: 5px 10px;
    border-radius: 0;
    font-size: 12px;
    margin-left: 6px;
    font-weight: 800;
    color: #90989a;

    .badge {
      font-size: 10px !important;
      padding: 2px 5px !important;
      font-size: 11px !important;
      border-radius: 5px !important;
    }
  }

}

.btn-default {
  background: rgb(251 252 255 / 78%);
  color: #333 !important;
  box-shadow: 1px 3px 10px rgb(132 132 132 / 18%);
  border: 1px solid #cad7de;

  &:hover {
    color: #000 !important;
    background: $primary;
    border: 1px solid darken($primary, 5%);

  }

  &:active {
    color: #000 !important;
    background: darken($primary, 5%);
    border: 1px solid darken($primary, 9%);
  }
}


.card {
  .btn-last {
    margin-bottom: 10px;
    margin-top: -5px;
  }
}


.link-box {
  position: relative;
  border-radius: 5px;

  a, button {
    display: inline-block;
    padding: 10px 20px;
    color: darken($app-color, 5%);
    font-size: 12px;
    box-shadow: none !important;
    border: none;
    outline: 0 !important;
    cursor: pointer;
    text-decoration: none;
    margin-left: 3px;
    background: none;
    border-radius: 5px;

    span {
      @media (max-width: 1000px) {
        display: none;
      }
    }

    &:hover {
      background: lighten($primary, 10%) !important;
      color: #111 !important;
    }

    &:first-child {
      margin-right: -3px;
    }

    &:last-child {
      border-left: none;
    }

    svg {
      color: #9a9a9a;
      height: 14px;
      width: 15px;
      margin-left: 3px;

    }
  }

  a.active, button.active {
    background: #404b4e;
    color: #fff;

    svg {
      color: #fff;
    }
  }
}

.link-box.big {

  a, button {
    display: inline-block;
    padding: 8px 25px;
    color: darken($app-color, 5%);
    font-size: 13px;
    box-shadow: none !important;
    border: none;
    outline: 0 !important;
    text-decoration: none;
    cursor: pointer;
    background: none;

    &:hover {
      background: lighten($primary, 2%) !important;
      color: #111 !important;
    }

    @media(max-width: 768px) {
      padding: 8px 19px;
      font-size: 11px;
    }

  }

  .active {
    color: #000;
    font-weight: 800;
    position: relative;

    &:after {
      position: absolute;
      bottom: -10px;
      right: 14px;
      content: "";
      width: 10px;
      height: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      border-top: 10px solid $primary;
    }
  }
}

.link-box.order {
  .active {
    background: $primary;
  }
}

.details-box {
  position: relative;

  h1 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #2a3133;
    position: absolute;
    top: -10px;
    right: 39px;
  }

  background: lighten($bg, 10%);

  padding: 20px;


}

.no-data-table {
  text-align: center;
  padding: 40px 0;
  color: #b6b8c0;
  font-weight: 500;

  svg {
    height: 50px;
    width: 50px;
    fill: #b6b8c0;
    margin-bottom: 15px;
  }
}

.form-control, .DatePicker__input {
  width: 100%;
  height: $input-height;
  padding: 0.4rem 1rem;
  outline: 0 !important;
  font-family: 'bakh-bold' !important;
  font-size: 13px;
  height: 38px;
  padding: 5px 11px;
  letter-spacing: 0.5px;
  font-weight: 400;
  border-radius: 7px;

  color: $app-color;
  background-color: #f1f5f94d !important;
  border: 1px solid rgba(162, 171, 190, 0.8) !important;
  box-shadow: inset 0 0 5px rgb(204 204 204 / 19%) !important;

  &:focus, &:active, &:visited {
    border: 1px solid rgba(20, 59, 68, 0.91) !important;
    box-shadow: 0 2px 5px rgba(10, 48, 54, 0.16) inset !important;
    color: $app-color;
  }

  @media(max-width: 768px) {
  }

}

.DatePicker {
  display: block !important;

  input {
    text-align: right;
    font-size: 13px;
  }
}

.user-info-profile {
  .item {
    padding: 13.7px;;

    .title {
      opacity: 0.6;
    }
  }
}

.user-info-avatar {
  img {
    display: block;
    margin: 0 auto;
  }
}

.user-profile-security {
  border: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
  background: #343b3e;

  .list-group-item {
    border: none !important;
    background-color: #fafbfb;
    border: 1px solid rgba(150, 150, 150, 0.13);
    margin-bottom: 0 !important;
    font-weight: 600;
    padding: 16px 18px 0;
  }
}

.user-profile-document {
  font-weight: 700;

  svg {
    height: 40px;
    width: 50px;
    fill: #626f73;
    margin-left: 10px;
  }

  .btn-link {
    color: #ec9b22;
  }

  .qs {
    svg {
      height: 20px;
      width: 20px;
      color: #e43f52;
      fill: #f5e1e3;
      cursor: pointer;
    }
  }

  .footer {
    background: #3d4548;
    height: 32px;
    margin: -24px;
    margin-top: 20px;
    position: relative;

    .btn {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    small {
      display: block;
      padding: 7px;
      color: #9e9e9e;
    }
  }
}


.english, .ltr, .eng {
  text-align: left;
  direction: ltr;
}


.modal {
  background: rgba(226, 226, 226, 0.35);
}

.modal-content {
  background-color: #fff !important;
}

.modal-header {
  display: block;
  border-bottom: 1px solid #eee;

  .modal-title {
    float: right;
  }

  .close {
    float: left;
    outline: none;
    font-size: 33px;
    padding: 0;
    margin-top: -4px;
  }
}


.dropify-wrapper {

  background: #fdfdfd !important;
  border: 1px solid #eaeaea !important;
  border-radius: 7px;

  &:hover {
    background: rgba(81, 106, 136, 0.4) !important;
  }

}


.popover {
  font-family: $font-family-base !important;
}


.header-buttons {
  .btn {
    maargin-bottom: 21px;
    border-radius: 8px !important;
    font-weight: 800;
    padding: 7px 17px;
    font-size: 12px;
    color: #4A517D;
    border-radius: 14px !important;

    svg {
      fill: none;
    }

    &:hover {
      border: 1px solid darken($primary, 7%) !important;
      background: darken($primary, 7%) !important;
      color: #111 !important;
    }

    &.primary {
      background-color: #ffd245;
      border: 1px solid #e3b629 !important;
      background-image: linear-gradient(273deg, #f4bf1a 0%, #e0e628 100%);

      color: #333;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
      font-family: bakh-black;


      &:hover {
        background-image: linear-gradient(
                        180deg, #e0e628 0%, lighten(#e6c628, 10%) 100%);
        color: #111;
      }
    }

    @media(max-width: 1000px) {
      margin-bottom: 0px;
      font-size: 10px !important;
    }

    svg {
      height: 17px;
      width: 17px;
      margin: 0;
      margin-left: 2px;
      display: inline-block;
    }
  }
}

//.alert-danger {
//  color: #fff5f6;
//  background: $danger;
//  -webkit-box-shadow: none;
//  box-shadow: none;
//}

.alert {
  margin-bottom: 15px !important;
  background: #fff !important;

  .close {
    float: left;
  }
}


.panel-tickets {
  .avatar-lg {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 43px;
    font-size: 18px;
  }
}


.plus-file-upload {
  .btn-add {
    border-radius: 50%;
    padding: 3px 2px;
    font-size: 11px;
    width: 20px;
    height: 20px;
    margin-top: 7px;
    border: 2px solid #83b970 !important;

  }

  .btn-del {
    font-size: 13px;
    color: #f00;
    background: none;
    border: none;
  }

  input[type=file] {
    font-size: 10px;
    margin-bottom: 5px;
    float: right;
    width: 160px;
  }
}

.btn-toolbar {
  display: none;
}

.media-body {
  p {
    color: $dark-4 !important;
  }
}


.comment-area-box {
  .admin {
    background: #495254;
  }
}


.alert-card {

  .msg-icon {
    font-size: 48px;
  }

  .title {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: -15px;
    font-weight: 800;
  }
}

.btn-transparent {
  background: rgba(0, 0, 0, 0.1);
}

.btn {
  &:hover {
    color: #fff;
  }
}


.input-group-text {
  background-color: #f7f7f7;
  border: 1px solid #a2abbe;
  color: #333;

  &:hover {
    background: #cbccd2;
  }

  padding: 0.2rem 1rem;

  @media(max-width: 768px) {
    padding: 3px 12px;
  }
}


.card-list {
  border: 2px solid #42484a;

  li {
    list-style: none;
    background: rgba(255, 255, 255, 0.1);

    strong {
      color: $primary;
    }
  }
}

.jq-toast-single {
  font-family: iranyekan !important;


  h2 {
    font-family: iranyekan !important;


  }
}


.comments {

  .child {
    padding-right: 30px;
  }

  .admin {

    .comment-body {
      border-right: 3px solid $primary;

    }
  }

  .comment {
    margin-top: 22px;

    img {
      border-radius: 6px !important;
      border: 2px solid #fff;
      box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    }

    .name {
      font-size: 13px;
      font-weight: 700;
    }

    .date {
      font-size: 11px;
      color: #6d777e;
      font-family: 'bakh-light';
    }


    .comment-avatar {
      position: relative;

      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      border-radius: 50%;
      background-clip: padding-box;
      display: block;
      float: left;
      height: 50px;
      width: 50px;
    }

    .comment-body {
      font-size: 13px;
      margin-top: 6px;
      background: #fff;
      padding: 20px;
      border-radius: 7px;
      position: relative;
      box-shadow: 0 0 5px rgb(0 0 0 / 10%);

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #fff;
        right: -10px;
        top: 20px;
        position: absolute;
      }
    }

    .comment-footer {
      display: block;
      font-size: 12px;
      padding: 4px 12px 0;

      i {
        font-size: 13px;
        margin-right: 7px;
      }
    }

    .comment-footer, .comment-footer a {
      color: rgba(#222, 0.5);

      &:hover {
        color: $primary;
      }
    }
  }

  .comment > .comment {
    margin-right: 50px !important;

    .comment-body {
      background: #e1f0fb;

      &:before {

        border-left: 10px solid #e1f0fb;

      }
    }

  }
}

.calculator {
  position: relative;
  overflow: hidden;

  .help {
    color: #8ea1c2;
    font-size: 12px;
  }

  .buttons {
    padding: 0 13px;

    margin-bottom: 10px;

    .col {
      padding: 0;

      .btn {
        border-radius: 0;
        padding: 13px;
        font-size: 16px;
      }
    }
  }

  .btn-default {
    background: #f5f5f5 !important;
  }


  .info {
    font-size: 12px;
    color: #9aa8b7;
  }

  .info-box {
    padding: 10px 15px;
    font-size: 11px;
    border-radius: $border-radius-app;

    background: #eeeeee;
    border: 1px solid #eaeaea;
    color: #4e5151;

    .item {
      padding: 10px 0;
      border-bottom: 1px solid #e3e3e3;

      font-size: 12px;

      &:last-child {
        border: none !important;
      }
    }

    .item.total {
      border-bottom: none;
      padding: 15px 0;
      font-weight: 800;
      color: #262626;
      font-size: 18px;
    }
  }

  .amounts {
    padding: 0 15px;

    .col {
      padding: 0;
    }

    .or {
      width: 34px;
      -webkit-box-flex: inherit;
      -ms-flex-positive: inherit;
      flex-grow: inherit;
      padding: 6px 8px;
      font-weight: 500;
      font-size: 14px;
      border-right: none;
      border-left: none;

      background: #bdc2cf;
      color: #393939;
      border: 1px solid #dcdee3;
    }
  }

  .input-group {
    .form-control, input {
      border-radius: 5px !important;
    }
  }

}

.loader {
  background: rgb(255 255 255 / 99%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;
  border-radius: $border-radius;


  img {
    display: block;
    margin: 0 auto;
  }

  .page--loading & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.show {
    display: flex !important;
  }
}

.network_list {
  background: rgb(0 0 0 / 10%);
  padding: 7px;
  border: none;
  border-radius: 5px;


  .item {
    background: #c7c7c7;
    color: #313131;
    margin-left: 10px;
    font-size: 10px;
    padding: 6px;
    cursor: pointer;
    border: 2px solid #e1e1e126;

    border-radius: $border-radius-app;

    &:hover {
      border-color: $primary;

      .name {
        color: #111 !important;
      }
    }

    .name {
      font-weight: 700;
    }

    .fee {
      font-size: 11px;
      color: #7c7e81;
    }
  }

  .item.active {
    border: 2px solid $primary;
    color: #111 !important;
    background: lighten($primary, 30%);

    &:hover {
      color: #111 !important;
    }

  }

}

.form-error {
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: 0px;
  padding: 2px 9px 6px;
  background: #e21314;
  color: #fbc3c3 !important;
  font-weight: 500;
  border-radius: 4px;
  top: 8px;
  left: 5px;
  font-family: tahoma;
  line-height: 18px;
  position: relative;
  width: fit-content;

  &:after {
    content: "";
    position: absolute;
    right: 10px;
    top: -6px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #e21314;
  }

}


.select2-results img, .select2-selection__rendered img {
  vertical-align: middle;
  border-style: none;
  float: right;
  margin-left: 5px;
}

.select2-dropdown {
  background-color: #fff !important;
  border: 1px solid #eee !important;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #fff !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #eee !important;
  background: #fff !important;

  &:focus {
    box-shadow: none !important;
    border: 1px solid #eee !important;

  }
}

.parsley-errors-list {
  margin: 0;
  padding: 0;

  > li {
    list-style: none;
    color: $danger;
    margin-top: 5px;
    padding-left: 20px;
    position: relative;

    &:before {
      content: "\F159";
      font-family: "Material Design Icons";
      position: absolute;
      left: 2px;
      top: -1px;
    }
  }
}

.parsley-error {
  border-color: $danger;
}

.parsley-success {
  border-color: $success;
}

.invoice {
  .prices {
    margin-top: 25px;
    background: #272d2d;
    padding: 30px 30px 0;
  }

  .radio-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 24px;

    .card {
      font-weight: 400;
      border: 2px solid #1b1e1f;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 7px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
      text-align: center !important;
      @include transition(0.3s ease);

      &:after {
        clear: both;
        content: '';
        display: block;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 2;
      right: 0;

      &:checked ~ .card, &.active ~ .card {
        border: 2px solid $t-color;
        color: #fff;

        svg {
          fill: $t-color;
        }

        h4 {
          color: $t-color;
        }
      }
    }

    .card {
      text-align: right;
      display: block;
      text-align: right;

      &:hover {
        cursor: pointer;
        border-color: $primary;

        svg {
          fill: #fff;
        }

        h4 {
          color: #fff;
        }
      }

      svg {
        display: inline-block;
        height: 30px;
        width: 30px;
        fill: #aaa;
      }

      h4 {
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        width: fit-content;
        margin-right: 10px;
        margin-top: 4px;
      }
    }

  }
}

.jq-icon-error {
  background-color: #ec1e19 !important;
}

.jq-icon-success {
  background-color: $success !important;
}


.dropdown-menu {
  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }
}

.fancy-checkbox {
  margin-right: 0;
  margin-left: 0;

  input[type=checkbox] + span:before {
    margin-right: 0;
    margin-left: 8px;
  }
}

.fancy-checkbox input[type=checkbox]:checked + span:before {
  color: $primary;
}

.fancy-checkbox.parsley-error input[type="checkbox"] + span:before {
  border: 1px solid rgba(238, 89, 89, 0.38) !important;
}


input.parsley-error, select.parsley-error, textarea.parsley-error {
  color: #B94A48 !important;
  background-color: rgba(238, 89, 89, 0.05) !important;
  border: 1px solid rgba(238, 89, 89, 0.38) !important;
}

input.parsley-success, select.parsley-success, textarea.parsley-success {
  color: #468847 !important;
  background-color: rgba(9, 98, 49, 0.04) !important;
  border: 1px solid rgba(13, 156, 77, 0.47) !important;
}

.parsley-errors-list li {
  color: $danger !important;
  font-size: 0.8em !important;
  margin-top: 6px !important;
}

.errrr {
  background: $danger;
  font-size: 10px;
  border-radius: 4px;
  padding: 3px;
  margin-top: 5px;
  color: #fff;
  display: none;
}

.red {
  color: $danger;
}

.green {
  color: $success;
}


.ltr {
  text-align: left;
  direction: ltr;

  ::placeholder {
    text-align: left;
    direction: ltr;
  }
}

.form-group, .form-group-2 {
  position: relative;

  .error-label {
    position: absolute;
    background: #e21314;
    color: #fbc3c3 !important;
    padding: 3px 10px 7px;
    font-weight: 500;
    border-radius: 4px;
    top: 9px;
    left: 5px;
    font-family: tahoma;
    line-height: 10px;

    &:after {
      content: '';
      position: absolute;
      left: 6px;
      bottom: -6px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-top: 6px solid #e21314;

    }
  }

  &.date {
    .error-label {
      z-index: 100;
    }
  }
}

.input-code {
  text-align: center !important;
  font-weight: 800 !important;
  font-family: Arial !important;
  font-size: 20px !important;
  letter-spacing: 17px !important;

  &::placeholder {
    letter-spacing: 0 !important;
    text-align: center !important;
    font-family: $font-family-krub-medium !important;
  }
}


label {
  font-size: 14px;
  font-family: $font-family-krub;

  @media(max-width: 768px) {
    font-size: 12px;
  }
}

.card {

  @media(max-width: 768px) {
    margin-bottom: 15px !important;
  }

}

.card-title-box {
  .title {
    font-family: 'bakh-bold' !important;
    font-size: 22px;
    margin-bottom: 32px;
  }
}

.btn {
  padding: .495rem .75rem;
  font-family: $font-family-krub-bold;
  font-size: 13px;
}


.alert-top-fix {
  border: none;
  border-radius: 0;
  margin-top: 0px !important;
  background: #df2525;
  font-family: $font-family-krub;
  font-weight: 400;
  text-align: center;
  font-size: 14px;

  a {
    color: #ffe97b;
    font-weight: 800;
    font-family: $font-family-krub-heavy;
  }

  p {
    margin: 0;
  }
}

.fast-access-dashboard {
  a {
    display: block;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;

    svg {
      display: block;
      margin: 0 auto 10px;
      height: 30px;
    }
  }

}

.Toastify__toast-body {
  font-family: $font-family-krub;
}

.links-box {
  margin-top: -8px;
  border-radius: 5px;

  a {
    display: block;
    padding: 10px 15px;
    /* border-left: 1px solid #4A517D; */
    position: relative;
    cursor: pointer;
    font-size: 13px;
    font-family: "bakh-bold", roboto, tahoma, sans-serif;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    background: rgb(255 255 255);
    margin: 2px;
    text-align: center;
    border: 1px solid #c2cddf;
    color: $app-color;


    &:hover, &.active {
      border: 1px solid #edd384;
      background: #fffae9;
      color: #000000;

      &:before {
        display: block;
      }

      i {
        color: #111;
        background: $primary;
        border: 1px solid darken($primary, 10%);
      }
    }

    i {

      color: #a3a3d2;
      display: block;
      width: 45px;
      height: 45px;
      font-size: 23px;
      padding: 11px;
      background: rgb(199 199 199 / 15%);
      border: 1px solid rgb(0 0 0 / 10%);
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 10px;

      @media(max-width: 768px) {
        width: 40px;
        height: 40px;
        padding: 7px;
        margin-bottom: 0;
      }
    }

    &:last-child {
      border-left: none;
    }
  }

}

.verify-email-box {
  .icon {
    height: 150px;
    margin: 20px auto;
    margin-top: -10px;
    display: block
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    font-family: $font-family-krub-heavy;
  }

  p {
    color: #d2d2d2;
    font-family: samim;
    font-size: 14px;
  }

  input {
    background-color: #545454 !important;
  }

  .send-code {
    background: none;
    border: none;
    outline: 0 !important;
    color: #cabea5;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;

    &:disabled {
      cursor: progress;
    }
  }
}

.verifications-card {
  .header {
    .link {
      text-align: center;
      position: relative;
      cursor: pointer;
      padding-bottom: 15px;
      padding-top: 10px;
      border-radius: 10px 10px 0 0;
      margin: 0 2px;

      &:hover {
        background: rgba(0, 0, 0, 0.05) !important;

      }

      &.hover {

        &:hover {
          .title {
            color: $primary;
          }

          color: $primary;

          .icon {
            fill: $primary;
          }
        }
      }

      .title {
        font-size: 15px;
        font-weight: 400;
        color: #696969;
        margin-top: 7px;
        @media(max-width: 768px) {
          font-size: 9px !important;
        }

      }

      .icon {
        height: 35px;
        width: 35px;
        fill: #848484;

        @media(max-width: 768px) {
          height: 20px;
          width: 20px;
        }
      }

      .left-arrow {
        position: absolute;
        left: 0;
        bottom: 10px;
        stroke: #ddd;

        @media(max-width: 768px) {
          svg {
            height: 12px;
            width: 12px;
          }
        }
      }

      &:last-child {
        .left-arrow {
          display: none;
        }
      }


      &.success {
        color: $success;

        .title {
          color: $success;
        }

        .icon {
          fill: $success;
        }

        .left-arrow {
          stroke: $success;
        }
      }

      &.pending {
        color: $warning;

        .title {
          color: $warning;
        }

        .icon {
          fill: $warning;
        }

        .left-arrow {
          stroke: $warning;
        }
      }

      &.warning {
        color: $warning;

        .title {
          color: $warning;
        }

        .icon {
          fill: $warning;
        }

        .left-arrow {
          stroke: $warning;
        }
      }

      &.danger {
        color: $danger;

        .title {
          color: $danger;
        }

        .icon {
          fill: $danger;
        }

        .left-arrow {
          stroke: $danger;
        }
      }

      &.active {
        color: $t-color;

        border-bottom: 3px solid;
        position: relative;
        background: rgba(0, 0, 0, 0.05) !important;

        &:before {
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;

          border-top: 10px solid $t-color;
          position: absolute;
          bottom: -12px;
          right: calc((100% - 24px) / 2);
        }

        .title {
          color: $t-color;
        }

        .icon {
          fill: $t-color;
        }

        .left-arrow {
          stroke: $t-color;
        }
      }
    }
  }
}

.dash-verify-form {
  box-shadow: none !important;
  border: none !important;
  background: none !important;

  .error {
    border: 1px solid #f1f1f1;
    color: #333;
    background: #fdfdfd !important;
    box-shadow: 0px 0px 0px 0px rgb(104 129 180 / 10%) !important;
  }
}

.dash-card-title {
  font-family: $font-family-krub;
  text-align: center;

  svg {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    display: block;
    margin: 10px auto;
    margin-top: -9px;
    fill: #717b9a;
  }
}

.send-code {
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0.7px;
  height: auto;
  padding: 0;
  background: none;
  border: none;
  display: block;
  color: $primary;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  cursor: pointer;
  margin: 0 8px 0 0 !important;
}

button:disabled {
  opacity: 0.7;
  filter: grayscale(0.3);
  cursor: progress !important;
}

.dropify-wrapper .dropify-message span.file-icon {

  p {
    font-size: 17px;

  }
}


.table {
  border-radius: $border-radius-app;
  overflow: hidden;
}

.list-group {
  margin-bottom: 0;
  padding: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  border: 1px solid rgb(150 150 150 / 13%);
}

.btn-get-sheba {
  background: no-repeat;
  border: none;
  color: $t-color;
  position: absolute;
  right: 63px;
  top: 4px;
  font-size: 11px;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-line: overline;
  z-index: 5;
  outline: none !important;

  &:hover {
    color: darken($t-color, 5%);
  }
}


.page-not-found {
  background: #f1f1f1;
  position: fixed;
  color: #333;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 30px;
  font-family: $font-family-krub-heavy;
  z-index: 9999;

  .btn-primary {
    background: $primary !important;
    color: #111 !important;

    /* border: 2px solid #ffc727; */
    border-radius: 10px !important;
    outline: none;
    padding: 10px 27px;

    &:hover {
      background: #424242 !important;
      color: $primary !important;
    }
  }
}

.table-box {
  margin: -24px;

  @media(max-width: 768px) {
    margin: -14px;
  }

  .table {
    margin-bottom: 0;
  }
}

.pagination {
  padding: 0;

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 0;
    color: #7f858c;
    background-color: #ffffff;
    border: 1px solid $bg-light_v;

    &:hover {
      background-color: #eaeaea;
    }
  }
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-radius: 0 2px 2px 0 !important;

}

.page-item:last-child .page-link {
  margin-left: 0;
  border-radius: 2px 0 0 2px !important;
}

.page-item.active .page-link {
  color: #111 !important;
  background: $primary !important;
}

.page-item.disabled .page-link {
  color: #caced2 !important;
  background-color: #f5f5f5 !important;
  border-color: $bg-light_v;
  opacity: 0.7;
}

.table {
  @media(max-width: 768px) {
    tr:nth-child(even) {
      td {
        background: rgb(121 121 121 / 10%) !important;
        border-top: 1px solid #404040
      }

      background: rgb(121 121 121 / 10%) !important;
    }
  }

  //tr:nth-child(odd) {background: #FFF}
}

.table th, .table td {
  @media(max-width: 768px) {
    border-top: 1px solid #e7ebec;
  }
}

.app-header-breadcrumbs {
  padding: 17px 0;

  @media(max-width: 768px) {
    margin: -17px -14px 20px;
    padding: 11px 16px 18px !important;
    margin-bottom: 0;
  }
}

.card-error-message {
  font-size: 14px;

  @media(max-width: 768px) {
    font-size: 12px;
  }

  .msg-icon {
    color: $primary;
  }

  .title {
    font-weight: 800;
    font-family: $font-family-krub-heavy;
    font-size: 17px;
    margin: 14px 0 20px !important;

    @media(max-width: 768px) {
      font-size: 15px;
    }
  }

  .error-li {
    list-style: none;
    width: fit-content;
    padding: 0;
    margin: 0 auto;

    li {
      margin-bottom: 5px;
    }

    svg {
      height: 15px;
      width: 15px;
      fill: #c3c3c3;
      margin-left: 6px;
    }


  }
}

.btn-rounded {
  border-radius: 50px !important;
}

.finance-form {
  input {
    font-size: 21px;
    font-weight: 800;
    font-family: 'samim';
    text-align: center;
    height: 50px;
  }

  .balance {
    font-size: 20px;
    padding: 20px;
    border-radius: 2px;
    color: #fff;
    border-bottom: 1px solid #e1af11;

    .price {
      font-family: 'samim';
      font-weight: 800;
    }
  }
}

.card {
  .card-form {
    box-shadow: none;
    //margin: 30px 0;
    //background: rgb(255 255 255 / 2%);

    label {
      color: #fff;
      font-family: $font-family-krub-bold;
    }

    @media(max-width: 768px) {
      background: none;
    }
  }
}

.sweet-alert {
  background: #ffffff !important;
}


.alert.custom-info {
  .title {
    color: $app-color;
    font-family: $font-family-krub-bold;
    display: block;
    margin-bottom: 7px;

    i {
      color: $info;
      margin-left: 7px;
    }
  }

  color: $app-color;
  background: rgb(255 255 255 / 62%);

  &.nobg {
    background: none;
    border-bottom: 1px solid #1f1f1f;
    margin-bottom: 20px;
    padding: 0;
  }
}


.ticket-info {
  .ssss {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 13px;
    border-radius: 5px 0 7px;
    /* padding-bottom: 6px; */
    /* padding-right: 11px; */
    padding: 5px;
  }

  .title {
    color: lighten($app-color, 20%);
    font-size: 17px;
    font-family: $font-family-krub-bold;

    img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }

  .info {
    color: lighten($app-color, 20%);
    background: rgb(184 201 215 / 18%);
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    line-height: 23px;
    font-family: bakh-light;

    @media(max-width: 768px) {
      margin-bottom: 10px;
    }

    div {
      color: $app-color;
      font-weight: 500;
      font-family: bakh-bold;
    }
  }
}

.latest_order_table {
  table {
    tbody {
      tr {
        &:hover {
          background: rgb(130 130 130 / 10%) !important;
          cursor: pointer;
        }

        &:active, &:visited {
          background: rgb(130 130 130 / 10%) !important;
        }
      }
    }

  }
}


.ticket-content {
  color: #e4e4e4 !important;
  font-size: 14px;
}

.breadcrumb {
  padding: 0;
  background: none !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: auto;
  padding-left: 0.5rem;
  color: #6c757d;
}

.overBox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(245 245 245 / 72%);

  z-index: 10
}


.p-switch {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  float: left;
  font-size: 16px;
  color: #2b3858;
  line-height: 0;
  padding: 6px;
  margin-right: 20px;
  border-radius: 50%;
  background: #4a517d30;
  margin-top: -1px;
  text-shadow: 0 0 #000000d6;


  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 2;
    right: 0;

  }

}

.btn-copy {
  margin-right: 10px;
  border: none;
  font-size: 22px;
  padding: 0;
  line-height: 0;
  background: no-repeat;
  margin-top: -5px;
  color: #a0a0a0;
  cursor: pointer;
  outline: none !important;
  position: relative;
  display: inline-block;

  &:hover {
    color: $primary;
  }

  .copied {
    position: absolute;
    left: -50px;
    top: 0px;
    font-size: 10px;
    background: rgb(243 193 10 / 50%);
    display: block;
    height: 21px;
    line-height: 15px;
    color: #000;
    padding: 3px 7px;
    border-radius: 4px;
    transform: translateX(10px);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease-in;

    &.show {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }


}

.ref-code {
  color: #fff;
}

.order-crypto-coins {
  min-height: 60px;
  padding: 11px 20px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;

  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #a3a2a2bd;
  background-image: linear-gradient(to right, #f9f9f9 0%, #ecf1f7 51%, #f5f6f7 100%);


  &:hover {
    background: rgba(255, 255, 255, 0.12);
    border-color: $primary;

    .arrow {
      &:after {
        border-top: 10px solid $primary;
      }
    }

  }

  .title {
    font-family: $font-family-krub-heavy;
    font-size: 21px;
    margin-bottom: 0;

    small {
      margin-right: 7px;
      display: inline-block;
      color: #6c6c6c;
      font-family: $font-family-krub;
    }
  }

  .type {
    font-size: 27px;
    font-family: $font-family-krub-heavy;
    color: $primary;
    margin-bottom: 0;
  }

  .arrow {
    position: absolute;
    left: 42px;
    top: 45%;

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      border-top: 10px solid #aaaa;
    }
  }
}

.order-crypto-networks {

  .text-danger {
    color: #fe7070 !important;
  }

  .form-control {
    cursor: pointer;

    &:hover {
      border: 1px solid $primary !important;

      .arrow {
        &:after {
          border-top: 7px solid $primary;
        }
      }
    }
  }


  .arrow {
    margin-top: 10px;
    margin-left: 10px;

    &:after {
      content: "";
      width: 7px;
      height: 7px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      position: absolute;
      border-top: 7px solid #aaaa;
    }
  }
}

.crypto-networks-item {
  .item {
    background: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 7px;
    cursor: pointer;
    border: 1px solid transparent;


    .fee {
      direction: ltr;
      text-align: left;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid $primary;

    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid transparent;
      }

      .fee {
        * {
          color: #aaa !important;
        }
      }
    }
  }
}


.coins-box {
  max-height: 400px;
  overflow-y: auto;

  .order-crypto-coins-list {
    background: rgb(0 0 0 / 2%);
    color: #2e2e2e;
    padding: 6px 8px;
    cursor: pointer;
    border: 1px solid transparent;
    position: relative;
    border-radius: 4px;
    margin-bottom: 3px;

    &:hover {
      background: rgba(255, 255, 255, 0.12);
      border-color: $primary;

      .arrow {
        &:after {
          border-top: 10px solid $primary;
        }
      }

    }

    img {
      background: #f9f9f9;
      padding: 5px;
      border-radius: 5px;
    }

    .title {
      font-family: bakh-bold;
      font-size: 13px;
      margin-bottom: 0;

      small {
        margin-right: 7px;
        display: inline-block;
        color: #c7c7c7;
        font-family: $font-family-krub;
      }
    }

    .type {
      font-size: 14px;
      font-family: $font-family-krub-heavy;
      color: $primary;
      margin-bottom: 0;
    }

    .arrow {
      position: absolute;
      left: 42px;
      top: 45%;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        border-top: 10px solid #aaaa;
      }
    }
  }

  .more {
    text-align: center;
    padding: 12px;
    font-weight: 600;
    color: $primary;
    background: rgb(253 244 244 / 12%);
    letter-spacing: 1px;
  }

}


.order-crypto-search {

  .loading {
    text-align: center;
    padding: 2px 0;
    color: $primary;
    margin: 1px 2px;
    border-radius: 4px;
  }

}

.DatePicker__calendarContainer {
  z-index: 1015 !important;

}

.responsive-calendar {
  /* by setting font-size, all the elements will correspond */
  font-size: 7px !important; /* default to 10px */
}

@media (max-width: 1500px) {
  .responsive-calendar {
    font-size: 7px !important;
  }
}

@media (max-width: 1200px) {
  .responsive-calendar {
    font-size: 7px !important;
  }
}

@media (max-width: 768px) {
  .responsive-calendar {
    font-size: 6px !important;
  }
}

/* Large screens */
@media (min-width: 2500px) {
  .responsive-calendar {
    font-size: 9px !important;
  }
}

.vip-text {
  border-right: none !important;


  svg {
    height: 17px;
    width: 17px;
  }


  &.vip0, &.vip0 a {
    color: #a3aeb8;
  }

  &.vip1, &.vip1 a {
    color: #f65bff;
  }

  &.vip2, &.vip2 a {
    color: #597de8;
  }

  &.vip3, &.vip3 a {
    color: #34cb2e;
  }

  &.vip4, &.vip4 a {
    color: #dfb806;
  }


}

.vip-icon {
  height: 15px;
  width: 15px;
  margin-top: -5px;

  &.vip0 {
    fill: #a3aeb8;
  }

  &.vip1 {
    fill: #f65bff;
  }

  &.vip2 {
    fill: #597de8;
  }

  &.vip3 {
    fill: #34cb2e;
  }

  &.vip4 {
    fill: #dfb806;
  }
}

.notification-container {
  .notify {
    h2 {
      font-size: 16px;
      font-family: $font-family-krub-bold;

      i {
        color: $primary;
      }
    }
  }
}

.table {
  .btn {
    font-size: 11px;
    padding: 6px 10px 4px;
  }
}

.no-shadow {
  box-shadow: none !important;
}

.card-credit {
  border: 1px solid lighten($card-bg, 10%);;
  padding: 0;
  background: lighten($card-bg, 7%);
  border-radius: 5px;
  cursor: pointer;
  padding: 6px !important;
  margin-bottom: 7px;

  &:hover, &.active {
    background: lighten($card-bg, 6%);
    border: 1px solid $primary;

    .badge, &.active .badge {
      opacity: 1;
    }
  }

  .number {
    font-size: 15px;
    font-weight: 800;
    padding: 20px 13px;
    color: $gray-300;
    line-height: 0;
  }

  .badge {
    margin: 10px 0;
    border-radius: 5px;
    font-size: 9px;
    padding: 3px 5px 4px;
    //opacity: 0.5;
  }

}


.reward_item {
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #4A517D 14%, #171D41 100%, transparent) !important;
  color: #fff !important;

  box-shadow: none;
  border-radius: 16px !important;
  border: none !important;

  .discount {
    color: $primary !important;
  }


  h4 {
    background: #7278A3;
    margin: -25px;
    padding: 5px 14px 1px 0;
    margin-bottom: 7px;
    color: #fff;
    font-size: 15px;
    font-family: "bakh-bold";
    border-radius: 0 0 0 22px;

    &.b {
      background: #2984fc;
    }

    &.c {
      background: #7DC064;
    }

    //&.blue{
    //  #4285F4
    //}
  }

  .active {
    padding: 2px;
    position: absolute;
    left: -77px;
    top: 12px;
    width: 200px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    font-weight: 800;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .title {
    font-size: 20px;
    font-weight: 800
  }
}

.reward-price-box {
  background: #f4f8fb;

  font-size: 20px !important;

  .card-body {
    position: relative;

    img {
      position: absolute;
      left: 11px;
      top: 18px;
      height: 70px;
      width: 70px;
    }
  }

  &.tn {
    background: #30B3CF !important;
    color: #fff !important;
  }

  &.ts {
    background: #FE6150 !important;
    color: #fff !important;
  }

  h4 {
    font-size: 14px;
    font-weight: 600
  }

  h2 {
    font-size: 16px;
    font-weight: 800
  }
}

.normal-text {
  font-size: 14px;
  @media(max-width: 768px) {
    font-size: 12px;
  }
}

.Toastify__toast-body {
  text-align: right;
  direction: rtl;
}

.overlay-menu-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  right: 0;
  background: rgb(24 24 24 / 88%);
  z-index: 1;
  display: none;

  &.show {
    display: block;
  }
}

.modal-backdrop {
  z-index: 100;
}

.menu-mobile {
  background: $mobile-menu-bg;
  min-height: 60px;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 99;
  box-shadow: 0 0 20px rgb(32 32 32 / 93%);
  display: none !important;

  @media (max-width: $sidebar-responsive-size) {
    display: flex !important;
  }

  .link {
    text-align: center;
    font-weight: 600;
    text-decoration: none;

    > a {
      padding: 14px;
      color: #ddd;
      text-decoration: none;
      position: relative;

      .badge {
        position: absolute;
        left: 10px;
        border-radius: 5px;
        line-height: 7px;
        padding: 4px 4px 1px;

        @media (max-width: 390px) {
          left: 3px;
        }
      }

      @media (max-width: 500px) {
        font-size: 11px;
        padding: 12px !important;
      }

      &.active, &:hover {
        color: $primary;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    i {
      font-size: 17px;
      display: block;
      margin-bottom: 1px;
    }


    .has-submenu {

      position: absolute;
      bottom: 49px;

      @media (max-width: 500px) {
        bottom: 45px;
      }

      width: 100%;
      right: 0;
      text-align: right;
      list-style: none;
      background: #303758;
      box-shadow: 0px -5px 4px rgb(14 34 59 / 32%);
      padding: 10px 0 !important;
      border-bottom: 2px solid #262626;
      @include transition(0.3s ease);

      transform: translateY(-10px);
      opacity: 0;
      visibility: hidden;

      li {
        > a {
          display: block !important;
          padding: 8px 5px !important;
          border-bottom: 1px dashed #2b2b2b !important;
          margin: 0 19px;
          color: #e5e5e5;
          text-decoration: none;

          &.active, &:hover {
            color: $primary;
          }

          &:active {
            + .has-submenu {
              opacity: 0 !important;
              transform: translateY(-10px) !important;
            }
          }

        }

        &:last-child {
          a {
            border-bottom: none !important;
          }
        }

      }

      &.show {
        visibility: visible;
        transform: translateY(0px) !important;
        opacity: 1 !important;
      }

    }


  }

}

.user-top-box {
  //height: $user-top-box-height;
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  background: $mobile-menu-bg;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  padding: 10px 15px;
  display: none !important;


  .p-switch {
    margin-top: 4px;
    margin-right: 12px;
    margin-left: 5px;
  }

  .avatar {
    border-radius: 50%;
  }

  .email {
    padding: 2px 5px 0px;
    margin-top: -9px;
    color: #9b9b9b;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    color: #e1e1e1;
    font-family: $font-family-sans;
  }

  .user-level {
    svg {
      height: 16px;
      width: 20px;
      margin-left: 0px;
    }
  }

  @media(max-width: $sidebar-responsive-size) {
    display: flex !important;
  }

}

.mobile-handle {
  @media(max-width: $sidebar-responsive-size) {
    display: none;
  }
}


.dashboard-user-info {

  .d-title {
    font-size: 15px;
    font-weight: 400;
    font-family: $font-family-krub-bold;
    color: #fff;
    @media(max-width: 768px) {
      font-size: 13px;
    }

    i {
      color: $primary;
    }
  }

  .items-box {
    .item {
      padding: 3px 0;
    }

    .title {
      color: #c7c7c7;
      font-size: 13px;
      margin-right: 21px;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }


}

.dashboard-user {
  .card-table {
    padding: 11px !important;
    box-shadow: none !important;
    border: 1px solid #b1c4d947;
  }

  .component-loader {
    width: 100%;
    background: linear-gradient(180deg, darken($bg, 5%) 0%, transparent 100%);
  }

  .more {
    background: rgb(32 32 32 / 26%);
    padding: 7px 20px;
    text-align: center;
    border-radius: 50px;
    margin-top: 10px;
    letter-spacing: 1px;
    color: #d7d7d7;
    width: fit-content;
    margin: 0 auto;
    font-size: 10px;
    border: 1px solid rgb(0 0 0 / 18%);

    &:hover {
      color: $primary;
      background: rgb(32 32 32 / 36%);
    }

    margin-top: 10px;
  }

  .order-btn {
    font-size: 13px !important;
    padding: 5px 10px !important;
    margin-left: 10px !important;
    border: none !important;

    @media(max-width: 768px) {
      font-size: 11px !important;
    }

  }
}

.vip-layers {

  .vip-table {
    td, tr {
      align-items: center !important;
      justify-content: center !important;
      vertical-align: center !important;
      padding: 0;
    }

    position: relative;

    ul {
      padding: 0;
      margin: 0;
      padding-right: 10px;

      li {
        padding: 0;
        margin: 0;
      }
    }


    .table-bordered th, .table-bordered td {
      padding: 0.002px;
    }

    tr:nth-child(even) {
      background: #f5f6f8 !important;
    }

    table {
      width: 100%;
      padding: 0;
      margin: 0;

      td {
        padding: 5px 10px;
        @media(max-width: 768px) {

          padding: 5px 5px;
          font-size: 10px;

          &.row-title {
            height: 25.4px;
          }

          .vip-text {
            svg {
              height: 14px;
              width: 14px;
            }
          }
        }


      }


    }


    .current {
      -webkit-transform: scale(1.01);
      -moz-transform: scale(1.01);
      -ms-transform: scale(1.01);
      -o-transform: scale(1.01);
      transform: scale(1.01);
      background: #23c879;

      &:before {
        content: 'سطح فعلی شما';
        width: 100%;
        height: 25px;
        position: absolute;
        z-index: 1;
        top: -25px;
        left: 0;
        background: #23c879;
        color: #fff;
        border-radius: 5px 5px 0 0;
        text-align: center;
        padding: 4px;
        font-size: 11px;
        font-weight: 800;
      }

      td {
        background: #f7fffc;
      }

    }

  }


  .vip-s {
    font-size: 12px;
  }

  .vip-item-box {
    font-weight: 400;

    .vip-item {
      margin-bottom: 7px;
    }

    .vip-item span {
      color: $t-color;
      text-transform: uppercase;
    }
  }


  .btn-sk {
    background: #edeef5;
    border: 1px solid #c0cad8;
    color: #3e8ab7;
    font-size: 13px;
    margin-bottom: 25px;
    border-radius: 4px;
    font-weight: 800;

    &:hover {
      background: #3e8ab7;
      color: #fff;

    }
  }

}


.app-alert {
  .content {
    word-break: break-word;

    @media(max-width: 1000px) {
      padding: 10px !important;
    }
  }
}

@keyframes ani {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.component-loader {
  min-height: 200px;
  box-shadow: none;
  background: rgb(190, 190, 190);

  border-radius: $border-radius-app;
  background: linear-gradient(180deg, #fff 0%, $bg 100%);

  h5 {
    letter-spacing: 1px;
    font-size: 13px;
    margin-top: 3px;
    animation-name: ani;
    animation-duration: 1s;
    transition: 1s;

  }
}

.is-number {
  text-align: left;
  direction: ltr;
  font-family: Roboto, tahoma !important;
  font-weight: 500;
  letter-spacing: 1px;
}

.is-number-input, input[type=number], .price, .amount {
  letter-spacing: 1.5px;
}

.card-table {
  margin-bottom: 6px !important;
  border-radius: 18px !important;
}

.card-table, .card-bank {
  .btn {
    background: #e6e6e62e !important;
    margin-right: 5px !important;
    font-size: 11px !important;
    box-shadow: none;
    border: 1px solid #dcdcdc;
    color: #3b3b3b !important;
    padding: 4px 12px !important;
    margin-top: 10px;
    box-shadow: 0 3px 8px rgb(0 0 0 / 10%);

    &:hover {
      background: $primary !important;
      border-color: darken($primary, 15%);
      color: #111;
    }
  }
}


.empty-list {
  background: rgb(157, 164, 168);
  border-radius: $border-radius-app;
  //background: linear-gradient(
  //                180deg, rgb(51 51 51) 0%, rgb(47 47 47) 100%);

  background: linear-gradient(180deg, #fff 0%, $bg 100%);

  padding: 50px 0;


  i {
    font-size: 70px;
    color: #dadada;
  }

  .title {
    font-size: 15px;
    color: #cdcdcd;
    margin-top: 11px;
    font-weight: 600;
  }

}

.card {
  .empty-list {
    background: none;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.alert-top-fix {
  background: #593636 !important;
  color: #f0dada !important;
  margin-bottom: 0;

  @media(max-width: 1000px) {
    text-align: right;
    margin: -19px;
    margin-top: -29px !important;
    padding: 10px 25px;
    font-size: 10px;
  }
}

.referrals-page {
  .h4-title {
    @media(max-width: 768px) {
      font-size: 13px;
      font-weight: 700;
    }
  }

  .title-h5 {
    @media(max-width: 768px) {
      font-size: 14px;
      font-weight: 700;
    }
  }
}


.oo-box {
  min-height: 182px;
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
  border: 2px solid transparent !important;
  text-decoration: none !important;

  .title {
    font-size: 13px;
    font-weight: 800;
    color: #d3d3d3;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
    text-decoration: none !important;
  }

  //&.crypto {
  //  border: 2px solid #ffcf00 !important;
  //  background: #ffdf0026 !important;;
  //}
  //
  //&.cash {
  //  border: 2px solid #36ba10 !important;
  //  background: rgb(125 228 61 / 18%) !important;
  //}

  &:hover {
    border-color: $primary !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .title {
      color: $primary;
    }

  }

  img {
    margin: 5px auto;
    display: block;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
  }

  @media(max-width: 768px) {
    border: none !important;
    border-bottom: 1px solid #8c8c8c5c !important;

    .title {
      font-size: 11px;
    }
  }

}


.services-items-box {


  .r {
    @media(max-width: 768px) {
      padding-left: 5px;
    }
  }

  .l {
    @media(max-width: 768px) {
      padding-right: 5px;
    }
  }

  a {
    text-decoration: none;
  }

  @media(max-width: 768px) {
    padding: 0px 0 7px 0 !important;
  }

  &.active {
    @media(max-width: 768px) {
      padding: 0px 0 7px 0 !important;
    }
  }

  .card-order-item {
    padding: 13px !important;
    font-weight: 700;
    font-size: 24px;
    box-shadow: 1px 2px 5px rgb(122 122 122 / 13%) !important;
    margin-bottom: 15px !important;
    @include transition(0.3s ease);

    word-break: normal;

    @media(max-width: 768px) {
      padding: 0px 19px !important;
      margin: 2px 7px !important;
      min-height: auto;

      img {
        height: 30px !important;
        width: 30px !important;
        filter: grayscale(0) !important;
      }

      .title {
        font-size: 12px !important;
      }
    }

    img {
      margin: 0;
    }


    .title {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      margin-right: 10px;
      color: $app-color;
      font-family: 'bakh-bold';

      @media(max-width: 1200px) {
        font-size: 12px;
      }

      @media(max-width: 768px) {
        font-size: 11px;
      }

    }

    &.column {
      .title {
        margin-right: 0;
        margin-top: 10px;
      }
    }

    &:hover {
      background: lighten($primary, 40%);

      .title {
        color: $dark
      }
    }

  }

  &.page {
    .card-order-item {
      padding: 10px 15px !important;
      margin-bottom: 8px !important;

    }
  }

}


.cash-box {
  .amount {
    font-size: 17px;
    font-family: $font-family-sans !important;
    margin-top: 10px;
  }

  .wallet {
    font-size: 14px;
    color: lighten($app-color, 10%);
    font-weight: 500;
  }

  #demo {
    font-size: 20px;
    color: #ff3b00 !important;
  }
}

.cashincomes {


  .head {
    img {
      margin-left: 10px !important;
    }

    h1 {
      font-size: 21px;
      margin-top: 7px !important;
      font-weight: 800;

      @media(max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .rec {
    padding: 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.big {
      .title {
        font-size: 15px;
      }

      .value {
        color: $primary;
        font-size: 15px;
      }
    }

    &:last-child {
      border: none !important;
    }
  }
}

.height-100 {
  height: calc(100% - 20px) !important;
}

.order-show {
  h5 {
    font-size: 20px;
    font-weight: 800;

    img {
      margin-left: 6px;
    }
  }

  .info {
    font-size: 14px;
    font-weight: 600;
    color: #5f7a9d;

    div {
      font-weight: 500;
      font-size: 13px;

      @media(max-width: 768px) {
        display: inline-block;
        margin-right: 6px;
      }
    }
  }

  .invoices-box {

    h5 {
      color: $app-color !important;
    }

    .font-weight-bold {
      color: $t-color !important;
    }

    .badge {
      padding: 7px;
    }

    .invoices {
      background: no-repeat;
      border-radius: 4px;
      padding: 5px;
      border: 1px dashed #c4cfde;
      margin-bottom: 5px;

      .item {
        padding: 7px;

        &:last-child {
          border: none;
        }
      }
    }

  }


}

.card-order-new {
  .hed {
    font-family: $font-family-sans;
  }
}

#box_info_prices {
  background: none;
  padding: 10px;
  border-radius: 5px;
  border: none;

  border-top: 2px solid #c8d4e3;
  border-radius: 0;
  padding: 21px 0;

  .currency {
    color: $t-color;
  }

  .loading {
    padding: 20px 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 5;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
  }

  .title {
    font-weight: 800;
    font-size: 15px;
  }

  .currency_price {
    padding: 5px !important;
    border: none !important;
    color: $t_color;
  }

  .prices {
    color: #222 !important;
  }

  .total {
    font-weight: 900 !important;
    border-top: 2px dotted #e4ebf3 !important;
    font-size: 17px !important;
    margin-top: 21px !important;
    padding: 12px 4px !important;
    color: $t-color !important;
    font-family: bakh-black;
  }
}


.form-control-select {
  background: none;
  padding: 15px 0 0 0;

  label {
    margin-left: 20px;
  }
}

.help-block {
  font-size: 12px;
  color: #b2b2b2;
  margin-top: 4px;
}

.btn-pr-qty {
  background: #eaf1f5 !important;
  font-size: 24px;
  text-align: center;
  padding: 3px;
  margin: 5px 0;
}

.btn-pr-qty:hover {
  background: $primary !important;;
  color: #111;
}

.input-qty {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  transform: scale(1.11) translateY(1px);
  border-radius: 0 !important;
  margin-bottom: 3px;
  margin: 5px;
  border-radius: 7px !important;
}

.show-info-order {

  font-size: 13px;
  font-family: $font-family-sans;
  font-weight: 300;

  ol, ul {
    padding: 0;
    margin: 0;
    padding-right: 22px;

    li {
      margin-bottom: 5px;
    }
  }
}

.digital-prices {
  img {
    height: 26px;
    margin-bottom: 0;
    margin-left: 9px !important;


  }

  table {
    font-size: 14px;
    font-weight: 400;
  }

  table thead {
    td, tr, th {
      border-top: none;
    }
  }

  .sss {
    @media(max-width: 768px) {
      font-size: 9px;
    }
  }
}


.digital-prices {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  input {
    width: 100%;
    border: 1px solid rgb(84 107 134 / 26%);
    border-radius: 4px;
    height: 33px;
    line-height: 28px !important;
    padding: 9px;
    background: rgb(234 241 245 / 9%);
    outline: 0 !important;
    box-shadow: 0 0 10px rgb(144 157 197 / 17%) inset;
  }


  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    color: #313131;
    line-height: 18px;
    padding: 20px 18px;
    font-weight: 800;
    font-family: $font-family-krub-bold;
    font-size: 14px;

    @media(max-width: 768px) {
      font-size: 12px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(242, 242, 242);
    color: #222;
    line-height: 18px;
    padding: 10px 18px;
    font-family: $font-family-krub-medium;
    font-size: 13px;
    font-weight: 500;


    a {
      color: #222;

      .img-box {
        margin-left: 10px;
        height: 36px;
        width: 36px;
        border-radius: 50%;

        &:before {
          width: 40px;
        }

      }

      img {
        height: 36px;
        width: 36px;
      }
    }

    .chart {
      background: none;
      margin-left: 10px;
      padding: 0;
      height: 36px;
      width: 100px;
      float: left;
      color: rgba(252, 216, 0, 0.2);
      @media(max-width: 768px) {
        width: 100%;
      }
    }


    &:hover {
      background: rgba(0, 0, 0, 0.06);
      cursor: pointer;
    }

    &.more {
      padding: 21px;
      background: #f7f7f7;
      text-align: center;

      &:hover {
        background: #eee;

        a {
          color: darken($primary, 5%);
        }

      }

      a {
        color: #74797b;
      }

      p {
        font-family: $font-family-krub-medium !important;
        color: #97999a;

      }
    }


  }

  .dg-name {
    width: 180px;
    flex: 180;

    @media(max-width: 768px) {
      flex: 90;
    }
  }

  .dg-price {
    width: 130px;
    flex: 150;
  }

  .dg-100 {
    width: 100px;
    flex: 100;
  }

  .new-price {
    background: rgba(194, 255, 59, 0.25);
    background: linear-gradient(to left, rgba(194, 255, 59, 0.25) 0%, rgba(255, 255, 255, 0.0) 100%);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .new-price-red {
    background: rgba(243, 142, 142, 0.25);
    background: linear-gradient(to left, rgba(243, 142, 142, 0.25) 0%, rgba(255, 255, 255, 0.0) 100%);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;

  }
}

.btn-buy {

  border: 1px solid #00bf1e !important;
  color: #00bf1e !important;
}

.btn-buy:hover {
  background: #00bf1e !important;
  color: #fff !important;
}

.btn-sell {
  border: 1px solid #F50000 !important;
  color: #F50000 !important;
}

.btn-sell:hover {
  background: #F50000 !important;
  color: #fff !important;
}

.btn-buy, .btn-sell {
  padding: 2px 4px;
  margin-left: 2px;
}

.pagi {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5)
}

.password-input {
  position: relative;

  .show-pass {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 2%;
    font-size: 22px;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    &:active, &:visited {
      color: darken($primary, 10%);
    }
  }
}

.user-edit-form {

  .faces {
    background: #e4eaf3 !important;
    height: auto;
    padding: 20px !important;
    border: 1px solid #cbd0dc !important;

    .col-3 {
      padding: 0;
    }

    img {
      display: block !important;
      margin: 6px auto !important;
      border-radius: 50px !important;
      border: 3px solid transparent;
      cursor: pointer;
      transition: 0.3s ease;
      padding: 2px;

      &:hover {
        border-color: lighten($primary, 30%);
      }

      &.active {
        border-color: $primary;
      }
    }
  }
}

.card-log-info {
  .card-table {
    margin-bottom: 6px !important;
    padding: 1px 10px !important;
    border-bottom: 1px solid rgb(213 213 213 / 50%) !important;

    border-radius: 0 !important;

    &:last-child {
      border-bottom: 0 !important;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.user-message {
  background: rgb(59 103 149 / 5%);
  padding: 15px !important;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;

  &:before {
    content: "";
    width: 6px;
    height: 3px;
    /* background: #f00; */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(59 103 149 / 5%);
    position: absolute;
    top: -10px;
    right: 8px;
    display: block;
  }


  .attach {
    margin: -14px;
    padding: 6px 12px;
    margin-top: 21px;
    font-size: 10px;
    background: rgb(65 125 188 / 5%);
    border-radius: 0 0 5px 5px;

    ul {
      padding: 0 12px;
      display: block;
      margin: 0;
      padding-top: 2px;
    }

    h5 {
      font-size: 13px;
    }
  }

}

.comment {
  .attach {
    margin: -15px;
    padding: 6px 12px;
    margin-top: 18px;
    font-size: 10px;
    background: rgb(116 116 116 / 3%);
    border-radius: 0 0 5px 5px;

    ul {
      padding: 0 12px;
      display: block;
      margin: 0;
      padding-top: 2px;
    }

    h5 {
      font-size: 13px;
    }
  }
}

.btn-primary {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.crisp-client .cc-kv6t[data-position-reverse=true][data-full-view=true] .cc-1xry .cc-unoo {
  @media(max-width: 768px) {
    bottom: 71px !important;
    font-size: 15px !important;
    left: -2px !important;
  }
}


.crisp-client .cc-kv6t[data-full-view=true] .cc-1xry .cc-unoo .cc-7doi {
  @media(max-width: 768px) {
    width: 31px !important;
    height: 40px !important;
    border-radius: 0 5px 5px 0 !important;
  }
}

.crisp-client .cc-kv6t[data-full-view=true][data-last-operator-face=false] .cc-1xry .cc-unoo .cc-7doi .cc-1iv2 .cc-15mo[data-is-ongoing=false] {
  @media(max-width: 768px) {
    width: 22px !important;
    height: 26px !important;
    top: 7px !important;
    left: 4px !important;
  }

}

.Toastify__toast-container {
  z-index: 99999999999999999 !important;
}

.cropper {
  margin: 20px auto;
  display: block;
}

.copper-box {
  .avatar-file {
    width: 150px;
    height: 150px;
    background: rgba(0, 0, 0, 0.1);
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }

  .btn {
    margin-top: 10px;
    border-radius: 50px;
  }
}

.break-all {
  word-break: break-all;
}

.ex-active {
  background: #222 !important;
  color: #fff !important;
}

.incc {
  background: #d3d7ed !important;
  border: none !important;
  border-radius: 0 !important;
  z-index: 0 !important;
  position: relative !important;
  width: 50px !important;
  padding: 0 !important;
  text-align: center !important;
  display: inline-block !important;
  padding-top: 10px !important;
  color: #2d2c28 !important;
  font-weight: 800 !important;
  margin: 2px;
  border-radius: 5px !important;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.link-box.crypto {
  background: none !important;

  a {
    font-size: 17px !important;
    margin-left: 7px;
    color: #fff !important;
    opacity: 0.5 !important;


    &:hover {
      color: #fff !important;
      opacity: 0.8 !important;
    }

  }

  .buy {
    background: $success !important;

    &:hover {
      background: $success !important;
      opacity: 1;
    }

    &:after {
      border-top: 10px solid $success !important;
    }
  }

  .sell {
    background: $danger !important;

    &:hover {
      background: $danger !important;
      opacity: 1;
    }

    &:after {
      border-top: 10px solid $danger !important;
    }
  }

  .active {
    opacity: 1 !important;
  }

}

.pop-qrcode {
  position: relative;
  font-size: 20px;
  padding: 5px;
  margin-right: 6px;
  cursor: pointer;

  .qr {
    position: absolute;
    box-shadow: 0 0 5px rgb(0 0 0 / 42%);
    padding: 9px;
    background: #fff;
    border-radius: 5px;
    display: none;
    bottom: -71px;
    left: -173px;
    z-index: 2;
  }

  &:hover {
    color: $primary;

    .qr {
      display: block;
    }
  }
}


.wallets {
  background: rgb(156 156 156 / 10%) !important;
  margin-top: 16px;
  padding: 13px;
  margin-bottom: 20px;
  border-radius: 8px;

  .head {
    margin-bottom: 15px;
  }

  .item {
    border-top: 1px solid rgb(0 0 0 / 11%);
    padding: 8px 0px;
    word-break: break-all;

    .title {
      color: #989898;
    }

    .value {
      font-weight: 600;
    }
  }
}


.app-slider {
  position: relative;
  margin-bottom: 10px;

  .slide {
    position: absolute;
    width: 100%;
    visibility: hidden;
    transition: 0.3s ease;
    opacity: 0;

    &.selected {
      visibility: visible;

      opacity: 1;

    }
  }

  .dots {
    display: flex;
    position: absolute;
    top: calc(100% + 10px) !important;
    right: -2px;

    .dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 2px rgb(0 0 0 / 50%);
      margin: 0 2px;
      opacity: 0.7;
      border: 1px solid rgba(0, 0, 0, 0.5);
      cursor: pointer;

      &.selected {
        background: $primary;
        opacity: 1;
      }
    }
  }


  img {
    border-radius: 5px;
  }
}

.auth-main {
  padding: 20px 0;

  .card {
    color: #a0a9e0;
  }

  h1 {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 23px;
    color: #fff;
    text-align: center;
    font-family: 'bakh-bold';
  }

  .card {
    box-shadow: none !important;
    background: none;
    border: none;


    .btn {
      font-weight: 500;
      font-size: 20px;
      border-radius: 50px !important;
      margin-top: 39px;
    }

    label {
      color: #e5e5ff;
      font-weight: 100;
      font-size: 13px;
      position: absolute;
      top: 12px;
      right: 14px;
      background: #0228b5;
      padding: 0 9px;
      transition: 0.3s ease;
      z-index: -2;
    }

    .form-group {
      &.focus {
        label {
          color: #dffbff !important;
          top: -6px !important;
          z-index: 6;
          font-size: 10px;
        }
      }
    }

    input[type=text], input[type=tel], input[type=number], input[type=email], input[type=password], select, textarea, .form-control {
      background: none !important;
      border-radius: 13px !important;
      border: 2px solid #a9b0d8 !important;
      height: 45px;
      font-size: 17px;
      margin-bottom: 20px;
      position: relative;
      z-index: 5;
      color: #fff;
    }

    input[disabled] {
      opacity: 0.5;
    }

    .center-label {
      label {
        display: block;
        text-align: center !important;
        margin-bottom: 19px;
      }
    }
  }

  .help {
    color: #fff;
    text-align: center;
    font-size: 15px;
  }

  .link {
    cursor: pointer;
  }

  .img-box {
    position: relative;

    .btt {
      position: absolute;
      height: 67%;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #0228b5;
      background: linear-gradient(180deg, transparent 0%, #0228b5 100%);
    }
  }

  .error-label {
    top: -18px !important;
    z-index: 6 !important;
  }

  .text-label {
    color: #e8eeef
  }

}


.vh-100 {
  height: 100vh;
}

.text-t {
  color: $t-color;
}

.input-tfa {
  .item {
    width: 50px;
    height: 50px;
    position: relative;

    .form-control {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}

.otp-code {
  &.error {
    input {
      border-color: #ff2323 !important;
    }
  }

  > div {
    justify-content: center;
    align-content: stretch;
    flex-direction: row-reverse;

    > div {
      width: 50px;
      height: 50px;
      position: relative;
      margin: 0 4px;

      span {
        margin-right: -13px;
      }

      input {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-family: 'bakh-black';
      }
    }
  }
}


.form-group-c {
  .error-label {
    color: #f00;
  }
}

.register-info {
  background: rgba(255, 255, 255, 0.1) !important;
  line-height: 25px;
  color: #dfe1f0 !important;
  font-family: 'bakh-light';

  h5 {
    font-family: 'bakh-bold';
    color: #eab716;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }
}

.auth-body {
  background: #0228b5 url(/assets/images/polygon-01-01.png) !important;
  background-size: cover !important;

  @media (max-width: 768px) {
    background: #0228b5 !important;
  }
}

.vhauth-100 {

  height: 100vh !important;

  @media (max-width: 768px) {
    height: auto !important;

  }

}

.vip-layers .vip-table table {
  font-family: 'bakh-reqular' !important;
}

.service-bold {
  padding: 20px;
  background: linear-gradient(135deg, #4A517D 0%, #171D41 100%);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;


  @media(max-width: 1000px) {
    display: block;
  }

  .buttons {

    .btn-success {
      color: #fff;
      background: #15d229;
      border: none;

      &:hover {
        background: #18e52d;
      }
    }

    .btn-danger {
      color: #fff;
      background: #fc2727;
      border: none;

      &:hover {
        background: #ff3a3a;
      }
    }

    .btn {
      margin: 0 3px;
      border-radius: 50px !important;
      font-size: 16px !important;
      padding: 7px 15px !important;
      font-family: 'bakh-black';

      @media(max-width: 768px) {
        font-size: 13px !important;
        padding: 7px 6px !important;
      }
    }

    @media(max-width: 1000px) {
      .d-flex {
        margin-top: 10px;
      }
      .order-8 {
        order: 12 !important;
        width: 100%;
      }

      .order-6 {
        order: 12 !important;
        width: 50%;
      }
    }
  }

  .icon {
    display: flex;
    color: #fff;
    align-items: center;

    h3 {
      font-size: 16px;
      margin: 0;
    }

    img {
      margin-left: 10px;
    }

    @media(max-width: 1000px) {
      display: block;
      text-align: center;

      h3 {
        font-size: 14px;
        margin-bottom: 10px;
        font-family: 'bakh-reqular' !important;
      }

      img {
        margin: 0;
        margin-bottom: 5px;
      }

    }
  }
}

.services-new {
  background: #e2e9f7;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;

  &:hover {
    background: #eeb60e !important;
    cursor: pointer !important;

    h6 {
      color: #000 !important;
      font-family: bakh-bold !important;
    }
  }

  &.active {
    background: #eeb60e !important;

    h6 {
      color: #000 !important;
      font-family: 'bakh-bold';
    }
  }

  h6 {
    margin-right: 10px;
    color: #313131;
    font-family: bakh-bold !important;
  }
}

.overlay-services {
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: rgb(0 0 0 / 46%);
  display: none;
  position: fixed;
  z-index: 999;

  &.show {
    display: block;
  }
}

.services_items_box {
  display: none;

  &.active {
    display: block !important;

    @media(max-width: 768px) {
      display: block !important;
      background: #fff;

      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 0 30px rgb(0 0 0 / 35%);
      border-radius: 24px 24px 0 0;
      ///* z-index: 9999999999999999999999999999999999999999999999999999999; */
      //height: fit-content;
      height: 70%;
      padding-bottom: 62px !important;

      .cont {
        padding: 0 10px 27px 10px !important;
        overflow: hidden;
        overflow-y: auto;
        height: 100%;
      }

    }
  }

  @media(max-width: 768px) {
    padding: 0px 0 7px 0 !important;
    background: #fff;

  }


  .card-order-item {
    padding: 10px 20px !important;

    img {
      transition: 0.2s ease !important;

    }

    i {
      @media(max-width: 768px) {
        font-size: 14px;
      }
    }

    &:hover {

      img {
        filter: grayscale(0);
        opacity: 1;
      }

      i {
        color: $t-color !important;
        opacity: 1;
      }
    }

    .title {
      font-family: 'bakh-reqular';
      font-size: 15px;

      i {
        transition: 0.2s ease !important;
        opacity: 0.5;

      }

    }

    @media(max-width: 768px) {
      padding: 10px 20px !important;
      border: 1px solid #738597;
      margin-bottom: 7px !important;

    }
  }

}

.ssss {
  @media (max-width: 768px) {
    z-index: 99999999999;
  }
}

.no-overflow {
  overflow: hidden !important;
  overflow-Y: hidden !important;
  overflow-X: hidden !important;
}

.ver-list {
  h5 {
    font-family: 'bakh-bold';
  }

  .link {
    border: 1px solid rgba(74, 81, 125, 0.3);
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background: rgba(231, 226, 159, 0.1);
    }


    .title {
      font-family: 'bakh-bold';
    }

    .badge {
      padding: 7px 10px;
      font-size: 11px;
      border-radius: 50px;
      margin-left: 11px;
      color: #fff;
      font-family: 'bakh-light';

      svg {
        margin-left: 6px;
      }
    }

    .arr {
      fill: #333;

    }

    .icon {

      background: rgba(74, 81, 125, 0.05);
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        fill: #cfcfcf;
      }
    }


    &.EMAIL {
      .icon {
        background: rgba(29, 197, 116, 0.1);

        svg {
          fill: #1DC574;
        }
      }
    }

    &.CREDIT {
      .icon {
        background: rgba(251, 137, 23, 0.1);

        svg {
          fill: #FB8917;
        }
      }
    }

    &.NATIONAL_ID {
      .icon {
        background: rgba(48, 179, 207, 0.1);

        svg {
          fill: #30B3CF;
        }
      }
    }

    &.PHONE {
      .icon {
        background: rgba(102, 102, 255, 0.1);

        svg {
          fill: #6666FF;
        }
      }
    }

    &.CONFIRMED {
      background: rgba(0, 0, 0, 0.05);
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.7;

      .icon {
        background: rgba(74, 81, 125, 0.05) !important;

        svg {
          fill: #cfcfcf !important;
        }
      }

      .arr {
        fill: #dddddd !important;
        transform: rotate(-180deg) !important;
      }
    }

  }
}


.popUpVerify {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  padding: 10px 0;

  z-index: 99999999;
  overflow: hidden;
  overflow-y: auto;

  background-image: url("/assets/images/bg-v.svg") !important;
  background-size: cover;
  background-repeat: no-repeat;

  .box {
    box-shadow: 0 2px 40px rgb(92 92 92 / 15%);
    background: rgb(255 255 255);
    padding: 32px;
    border-radius: 10px;
    overflow: hidden;

    @media(max-width: 768px) {
      margin-top: -40px;
    }

    .close {
      color: #222;

      &:hover {
        color: #000 !important;
      }

    }

    h5 {
      margin: -32px;
      margin-bottom: 30px;
      padding: 16px 25px;
      background: rgb(97 97 97 / 5%);
    }
  }

  .close {
    transform: scale(1.7);
    border: 2px solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
  }

  .title {
    font-size: 19px;
    font-family: 'bakh-black';
  }

}


.svg_b {
  background: rgba(74, 81, 125, 0.05);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  svg {
    width: 30px;
    height: 30px;
    fill: #cfcfcf;
    margin: 0 !important;
  }

  &.EMAIL {
    background: rgba(29, 197, 116, 0.1);

    svg {
      fill: #1DC574;
    }
  }

  &.CREDIT {
    background: rgba(251, 137, 23, 0.1);

    svg {
      fill: #FB8917;
    }

  }

  &.NATIONAL_ID {
    background: rgba(48, 179, 207, 0.1);

    svg {
      fill: #30B3CF;
    }

  }

  &.PHONE {
    background: rgba(102, 102, 255, 0.1);

    svg {
      fill: #6666FF;
    }

  }

}

.loadingAll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

@keyframes loadingSkeleteNew {
  0% {
    opacity: 0.2;
  }

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;

  }
}


@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.base-skelete {
  cursor: progress !important;
  background: #dce3ee;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 2s infinite;
    content: "";
  }


}

.skelete-loading {
  .base {
    cursor: progress;
    background: #dce3ee;
    //background: linear-gradient(0.25turn, transparent, #d8ddeb, transparent), linear-gradient(#e4eaf3, #e4eaf3), radial-gradient(38px circle at 19px 19px, #e4eaf3 50%, transparent 51%), linear-gradient(#e4eaf3, #e4eaf3);
    //background-repeat: no-repeat;
    /* background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; */
    //background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    overflow: hidden !important;
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0));

      animation: shimmer 2s infinite;
      content: '';
    }

  }

  .squre {
    width: 100%;
    border-radius: 15px;
  }

  .circle {
    border-radius: 100%;
  }

  .tablel {
    border-radius: 15px;
    overflow: hidden;

    .head {
      height: 50px;
      background: darken($light-bg, 5%);
    }

    .roww {
      height: 50px;
      border-bottom: 1px solid darken($light-bg, 5%);

      &.small {
        height: 30px;
      }
    }

  }
}

.card {
  .skelete-loading {
    .base {
      cursor: progress;
      background: linear-gradient(0.25turn, transparent, #edf0f6, transparent), linear-gradient(#f5f5f5, #f5f5f5), radial-gradient(38px circle at 19px 19px, #f5f5f5 50%, transparent 51%), linear-gradient(#f5f5f5, #f5f5f5);
    }
  }
}

.text-muted {
  color: #949495 !important;
}

.card {
  .alert {
    background: #fcfcfc !important;
    box-shadow: none;
  }
}


.text-primary {
  color: $t-color !important;
}

.card-bank {
  background: #fff important;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  border-radius: 15px !important;
  padding: 12px 23px !important;

  .card_number {
    text-align: center;
    font-size: 18px;
    letter-spacing: 4px;
    font-family: Tomorrow;
    font-weight: 800;
    color: #494949;
    @media(max-width: 768px) {
      font-size: 15px;
    }
  }

  .sheba {
    text-align: center;
    font-size: 10.5px;
    letter-spacing: 4px;
    font-family: Tomorrow;
    font-weight: 300;
    color: #6a6a6a;
    margin-top: 3px;
    @media(max-width: 768px) {
      font-size: 7px;
    }
  }

  .reject_message {
    height: 83px;
    width: 98%;
    padding: 15px;
    background: rgb(255 36 36 / 93%);
    color: #ffffff;
    font-size: 12px;
    position: absolute;
    top: 2%;
    left: 1%;
    right: 1%;
    border-radius: 5px;
    top: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50px);
    transition: 0.2s ease;

  }


  &:hover {
    .reject_message {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.vip-layers {
  .owl-item {
    opacity: 0.5;
    transform: scale(0.9) !important;

    &.center {
      opacity: 1;
      transform: scale(1) !important;
    }
  }
}

.card-vip-lc {
  padding: 20px;
  overflow: hidden;
  position: relative;

  min-height: 464px;


  .current {
    height: 31px;
    background: #f00;
    text-align: center;
    color: #fff;
    font-family: 'bakh-bold';
    font-size: 12px;
    padding: 7px;
    transform: rotate(45deg);
    position: absolute;
    right: -63px;
    width: 184px;
    top: 14px;
  }

  .items {
    .item {
      border-bottom: 1px solid #eee;
      padding: 9px 0;

      .title {
        flex-basis: 70%;
      }

      .value {
        font-family: bakh-bold;
        color: darken($app-color, 10%)
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  img {
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
  }
}

.vip-layer-table {
  .table {
    a {
      color: $app-color;

      &:hover {
        color: $t-color
      }
    }
  }
}


.referral-code {
  background: linear-gradient(45deg, #4A517D 33%, #171D41 100%, transparent) !important;
  color: #fff;

  .title-h5 {
    color: #fff;
  }
}

input[name=card_number] {
  height: 50px;
  font-size: 20px;
  font-family: 'bakh-black' !important;

  ~ .bank-css-sprite:after {
    top: 7px;
    right: 7px;
  }
}


.bills {
  .balance {
    font-family: bakh-bold;
    font-family: bakh-bold;
    height: calc(100% - 20px);
    justify-content: center;
    font-size: 14px;
    background: #f1fcff;


    .price {
      font-size: 20px;
      font-family: 'bakh-black';
    }

    @media(max-width: 768px) {
      padding: 22px !important;
      img {
        height: 45px;
        width: 45px;
      }
      font-size: 12px;
      .price {
        font-size: 17px;
      }
    }
  }

  .hi {

    img {
      height: 60px;
      width: 60px;
      margin: 0 auto;
    }

    .title {
      font-family: bakh-bold;
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
    }

    @media(max-width: 768px) {

      padding: 9px !important;

      img {
        height: 40px;
        width: 40px;
      }

      .title {
        font-size: 12px;
      }
    }
  }
}

footer.panel-footer {
  padding: 15px 5px;
  margin-top: 40px;
  border-top: 1px solid #cfd5e0;
  opacity: 0.5;
  transition: 0.4s;
  border-radius: 10px;


  &:hover {
    opacity: 1;
  }

  .footer-links {
    a {
      display: inline-block;
      margin-left: 19px;
      color: rgba(99, 111, 133, 0.79);
      font-size: 12px;
      font-family: bakh-bold;

      @media(max-width: 768px) {
        font-size: 11px;
      }
    }
  }


  element.style {
  }

  .input-group input, .input-group select, .input-group .form-control {
    border-radius: 0 7px 7px 0;
  }

  .input-group > .form-control, .input-group > .custom-select, .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }

  .ltr {
    text-align: left;
    direction: ltr;
  }

  .english, .ltr, .eng {
    text-align: left;
    direction: ltr;
  }
}


.badge {
  padding: 5px 7px;
  font-size: 75%;
  border-radius: 6px;
  font-family: 'bakh-light';
}


@keyframes loadingSkelete {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}

.dashboard-account {
  margin-top: 50px;
  object-fit: contain;

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  .btn-fast {
    background: rgb(43 80 222 / 7%);
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 10px;

    .btn {
      font-size: 9px;
      padding: 5px 9px;
    }
  }
}

.btn-range-picker {
  border: 1px solid #ffffff;
  border-radius: 11px;
  padding: 14px 12px;
  line-height: 0;
  background: #fff;
  box-shadow: 0px 2px 3px rgb(92 104 160 / 11%) !important;
  cursor: pointer;
  font-size: 11px;
  color: #000001;

  &:hover, &:focus {
    outline: 0;
    box-shadow: none;
    border-color: $primary;
    background: #fff;
  }
}

.ranges li {
  font-size: 10px !important;
  background-color: #f8f6f6 !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 4px !important;
  color: #2f3031 !important;
  padding: 3px 12px !important;
  margin-bottom: 8px !important;
  cursor: pointer !important;
  /* font-family: 'bakh-bold'; */
}

.JDatePicker {
  width: 300px;
  min-height: 210px;
  padding: 15px 10px 10px !important;
  position: absolute;
  background: #fff;
  border-radius: 15px !important;
  z-index: 1;
  box-shadow: 0 0 44px rgb(94 94 94 / 18%) !important;

  .JC-days {
    background: none !important;
    margin-top: 13px;
  }

  .days-titles{
    font-family: 'bakh-black';

    transition: 0.3s ease !important;
  }

  .JC-months {
    position: relative;
    display: inline-block;
    width: 100%;
    background: #f5f5f5;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    .holder:last-child {
      float: right;
      width: 100% !important;
    }

  }
  .JDheader {
    color: #00b2ff;
    margin-bottom: 10px;

    font-family: 'bakh-black';

  }

  .JC-years{
    justify-content: center;
    display: flex;
    span:first-child{
      display: none !important;
    }
  }
}

.JDatePicker .day-items:hover, .JDatePicker .day-items.selected {
  background: #ffae1c !important;
  color: #000 !important;
  border-radius: 5px !important;
}

.JDatePicker .JDheader .right .number:hover {
  border: none !important;
  cursor: text !important;
  background: #f5f5f5;
}

 .JDatePicker .JDheader .right .number {
   width: auto;
   direction: ltr;
   text-align: center;
   display: inline-block;
   font-size: 17px;
   letter-spacing: 4px;
   font-family: 'bakh-black';
   padding: 1px 17px;
   border-radius: 10px;
 }


.JDatePicker .JDheader .right input {
  border: none !important;
  cursor: text !important;
  background: #f5f5f5;
  box-shadow: none !important;
  width: auto;
  direction: ltr;
  text-align: center;
  display: inline-block;
  font-size: 17px;
  letter-spacing: 4px;
  font-family: 'bakh-black';
  padding: 1px 17px;
  border-radius: 10px;

  width: 60%;
   top: 0px !important;
  position: relative !important;
  border: 1px solid transparent !important;

  &:focus{
    outline: 0 !important;
    border: 1px solid $primary !important;

  }
}
.JDatePicker .JDheader .right, .JDatePicker .JDheader .left {
width: 100% !important;
}

.JDatePicker .JDheader .left {
display: none !important;
}

.JDatePicker .JC-tooltip {
position: absolute;
background: #ffeded;
z-index: 1;
padding: 0px 10px;
top: 39px;
line-height: 0;
font-size: 11px;
font-family: 'bakh-reqular';
/* border-radius: 10px; */
  width: 100%;
  padding: 18px 0;
  text-align: center;
  font-size: 12px;

  p{
    color: red ;
    line-height: 0;
    margin: 0;
    padding: 0;
    font-size: 11px;
  }
}


.rangeP{
  width: 200px;
  border: 1px solid #ffffff;
  border-radius: 11px;
  padding: 6px 12px;
  background: #fff;
  box-shadow: 0px 2px 3px rgb(92 104 160 / 11%) !important;
  cursor: pointer;
  font-size: 11px;
  color: #4b535b;
  position: relative;

  .ranges{
    position: absolute;
    background: #fff;
    z-index: 55;
    width: 100%;
    right: 0;
    top: 42px;
    border-radius: 10px;
    right: -4px;
    display: block;
    padding: 13px 23px 13px 13px;
    box-shadow: 1px 0px 20px rgb(0 0 0 / 10%);

    .custom{
      position: absolute;
      background: #e4eaf3;
      border: 1px;
      z-index: 17;
      width: 150px;
      height: 100%;
      right: -142px;
      top: 0;
      border-radius: 0 10px 10px 0;
      display: block;
      padding: 13px 13px 13px 15px ;
      box-shadow: 19px 6px 20px rgb(0 0 0 / 10%);
      border: 1px solid #ddd;
      cursor: auto !important;
      font-family: 'bakh-reqular';

      h5{
        padding: 0;
        margin: 0;
        text-align: center;
        margin-bottom: 20px;
        font-size: 13px;
        font-family: 'bakh-black';
        color: #505f62;
      }

      .jdtrp{
        width: 100%;

        > div{
          padding: 5px 0;
          text-align: center;
          display: block;
          color: #8695a3;
          font-family: 'bakh-black';
          font-family: 'bakh-reqular';

        }

        input{
          width: 100%;
          border: 1px solid #9fa7bc;
          height: 30px;
          padding: 10px;
          text-align: center !important;
          font-weight: 800;
          border-radius: 5px;
          font-family: 'bakh-reqular';

          &:hover,&:focus{
            outline: 0 !important;
            border: 1px solid $primary;
            box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
          }
        }
      }
    }

    .it{
      width: 100%;
      display: block;
      padding: 5px 10px;
      background: #f5f5f51a;
      margin-bottom: 3px;
      border-radius: 4px;
      text-align: right;
      border: 1px solid #eee;
      cursor: pointer;



      &:hover{
        background: $primary;
      }
    }
  }
}