/* Authentication forms */
.auth-main {
  height: 100%;

  .url{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 3px;
    margin: 0 auto 8px;
    width: 100%;
    padding: 5px 24px;
    align-items: center;
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #fff;
    letter-spacing: 2px;
    font-family: revert;

    svg{
      height: 11px;
      width: 11px;
      margin-right: 5px;
      fill: $success;
    }
  }


   .left {
    position: fixed;
    top: 0;
    height: 100%;
  }

  .right {

    width: 60%;
    overflow-y: auto;
    padding: 30px 0;

    @media(max-width: 1000px){
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 10px;
      right: 0;
      left: auto;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: rgba(122, 122, 122, 0.2);
    }
  }

  .left {
    left: 0;
    width: 40%;
    @media(max-width: 1000px){
      display: none !important;
    }


    background: #101010;
    padding: 65px;
    text-align: center;
    background-position: center center !important;
    background-size: cover !important;
    box-shadow: 5px 0 0 rgb(47, 47 ,47 , 0.43);
    img{
      filter: grayscale(1);
      height: 150px;
    }

    .title{
      color: #fff;
      font-family: 'YekanBakhHeavy';
    }

    .desc{
      color: #d0d0d0;
      padding: 0 26px;
      /* font-family: 'YekanBakhReqular'; */
      line-height: 30px;
    }


  }

  .auth_div {
    width: 55%;
    z-index: 999;
    margin: 20px auto;

    @media(max-width: 768px){
      width: 100%;
      .logo{
          width: 50px;
          height: 50px;
      }
    }


    .card, .card .body {
      overflow: hidden;
      background: no-repeat;
      border: none;
      box-shadow: none !important;

      .form-control, .dataTables_filter label input {
        background-color: $input-bg-color;
        border: none !important;
        font-size: 15px;
        height: $input-height !important;
        color: #eaeaea;
        box-shadow: none;
        border-radius: 3px !important;
        transition: 0.3s ease;
        font-family: $font-family-krub-heavy;
        font-weight: 800;
        border: 1px solid transparent !important;
        &::placeholder {
          color: #8e8e8e;
          opacity: 1;
          text-transform: capitalize;

        }

        @include transition(0.3s ease);

        &:focus, &:active {
          background-color: lighten($input-bg-color,3%);
          color: $primary;
          border-color:$primary !important;
        }
      }

      input:-internal-autofill-selected {
        appearance: menulist-button;
        background-color: #434343 !important;
        background: #434343 !important;
        background-image: none !important;
        color: #fff;
      }

      .btn {
        height: 50px;
        font-weight: 800;
        font-size: 18px;
      }

      label{
        color: #dedede;
        font-size: 12px;
        letter-spacing: 0.5px;
        margin-bottom: 0;
      }

    }

    .recover-pass{
      color: #989898;
    }

    .send-code{
      color: #ffcf0b !important;
      font-size: 12px;
      font-weight: 200;
      letter-spacing: 0.7px;
      height: auto;
      padding: 0;
      margin: -10px 4px 14px 0;
      /* margin-top: -10px; */
      background: none;
      border: none;
      display: block;
      color:$cyan;
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      cursor: pointer;
    }

    button:disabled{
      opacity: 0.7;
      filter: grayscale(0.3);
      cursor: progress !important;
    }

    .sign-up{
      font-size: 14px;
      font-family: 'YekanBakhBold';
      border: 1px solid;
      padding: 6px 12px;
      border-radius: 3px;
      -webkit-transition: 0.2s ease;
      -moz-transition: 0.2s ease;
      -ms-transition: 0.2s ease;
      -o-transition: 0.2s ease;
      transition: 0.2s ease;

      &:hover{
        background: $primary;
        color: #111;
        border-color: $primary;
        text-decoration: none;
      }
    }

    .auth-title-box {
      text-align: center;

      .auth-title {
        font-size: 27px;
        font-weight: 800;
        font-family: $font-family-krub-bold;
        color: #fff;

        @media(max-width: 768px){
          font-size: 18px;
        }
      }



      .auth-desc{
        font-size: 14px;
        color: #d2d2d2;
        font-family: samim;
      }

      margin-bottom: 20px;
    }

  }

  .auth_brand {
    .navbar-brand {
      color: $white;
    }
  }

  .form-auth-small {
    .btn {
    }

    .element-left {
      float: left;
    }

    .element-right {
      float: right;
    }

    .bottom {

    }
  }

  .lead {

    font-size: 20px;
    color: #aaa;
  }

  .helper-text {
    color: #aaa;
  }


}

.auth-main2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .auth_div {
    width: 930px;
    z-index: 999;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
      overflow: hidden;
      background: $white;
      border: 0;

      .body {
        border: none;
        background: transparent;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 70px 20px;

      }

    }

  }

}

@media(max-width: 768px){
  .grecaptcha-badge{
    right: -500px !important;
  }
}

