$app-color: #1d213f;
$d-app-color:#eee;
$d-sidebar:rgb(38, 43, 78);
$d-card-bg:#2e3456;

$d-card-color: #e5eef3;
$d-box-shadow: 0px 2px 3px rgb(26 30 49 / 15%) !important;

body.dark {

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #4a5593;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5967b4;
  }

  background: $app-color;

  color: $d-app-color;

  .n-app-sidebar {
    background: $d-sidebar;

    &::-webkit-scrollbar {
      width: 5px;
      background: $app-color;

    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: $d-card-bg;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: lighten($d-card-bg,5%);
    }

  }

  .card {
    background: $d-card-bg;
    box-shadow: $d-box-shadow;

    color: $d-card-color;

    .card-title {
      color: #fff;
    }

  }

  .dash-access {
    .link {
      color: #fff !important;

      &:before {
        background: #92abd81a !important;
      }
    }
  }

  .dashboard-user {
    .card-table {
      box-shadow: none !important;
      border: none;
      background: rgb(255 255 255 / 3%);
    }
  }

  .dashboard-rr .go-link a {
    color: #fff;
    border: 1px solid #ffffff3d;

    &:hover {
      color: $primary;
    }
  }

  .link-box.big a, .link-box.big button {
    color: $d-card-color;

    &.active {
      color: #111 !important;
    }
  }

  footer.panel-footer {
    border-top: 1px solid #dce1ea24;

    .footer-links a {
      color: rgb(116 136 174 / 79%);
    }
  }

  .skelete-loading {
    .base {
      background: #2b304f
    }
  }

  .n-app-main {
    .top-header {
      .events {
        background: rgb(108 129 166 / 26%);

        &:hover {
          svg {
            stroke: $primary;
          }
        }

        svg {
          stroke: #8c9bae;
        }
      }

      .phone {
        color: #e0e2ee;

        svg {
          fill: #e0e2ee;;
        }
      }

    }
  }

  .p-switch {
    color: #f4bf1a;
    background: #7b83b230;
  }

  .n-app-sidebar .left-sidebar .sidebar .menu ul li a {
    color: #cccede;

    &:hover, &.active {
      color: $t-color;
    }
  }

  .n-app-sidebar .left-sidebar .sidebar .menu ul li.active > a .icon, .n-app-sidebar .left-sidebar .sidebar .menu ul li.active > a .title {
    color: #fff;
  }

  .n-app-sidebar .left-sidebar .sidebar .menu ul li > ul {
    background: #414f6c45;
  }

  .n-app-sidebar .left-sidebar .sidebar .menu ul li a .i {
    background: rgb(81 91 112 / 34%);
  }

  .n-app-sidebar .left-sidebar .sidebar .menu ul li.active > a .i {
    background: #f4bf1a !important;
    color: #000;
  }

  .n-app-sidebar .verifications {
    border-top: 1px solid #9db9ec24;
    border-bottom: 1px solid #9db9ec24;
  }

  .dashboard-rr .balance {
    color: $primary;
  }

  .list-group-item {

    background-color: $d-card-bg;
  }

  select option {
    background-color: #363b5d !important;
    color: #d4d7e9 !important;

  }

  .form-control, .DatePicker__input {
    font-family: bakh-reqular !important;
    color: #d4d7e9;
    background-color: #7a7f9c5e !important;
    border: 1px solid rgb(23 34 58 / 80%) !important;
    box-shadow: inset 0 0 14px rgb(8 34 61 / 26%) !important;

    &:focus, &:active {
      border-color: $primary !important;
    }
  }

  .input-group-text {
    background-color: #4e5978;
    border: 1px solid rgb(23 34 58 / 80%);
    color: #c3cace;
  }

  .user-edit-form .faces {
    background: $d-card-bg !important;
    border: 1px solid $d-card-bg !important;
  }

  .modal-content {
    background-color: #404359 !important;

    .btn-default {
      background: #56586b ;
    }

    .list-group-item {
      background-color: #484b6066;
    }

  }

  .modal {
    background: rgb(126 130 154 / 88%);
  }

  .modal-header {
    border-bottom: 1px solid #7f889133;
  }
  .modal-footer {
    border-top: 1px solid #7f889133;
  }

  .close {
    color: #ffffff;
    text-shadow: 0 1px 0 #000000;

  }

  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    color: $primary;
  }

  .popUpVerify {
    background: #0e122c;

    .box {
      box-shadow: $d-box-shadow;
      background: #464c6e;

      h5 {
        background: rgb(21 30 46 / 20%);
      }

      .close {
        color: #fff;
      }

      .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
        color: $primary;
      }

    }
  }

  .dropify-wrapper {
    background: #4e5478 !important;
    border: 1px solid #393e5b !important;

    &:hover {
      background: #5b6188 !important;
    }
  }


  .ver-list .link {

    &:hover {
      background: rgb(205 235 251 / 10%);
    }

    .arr {
      fill: #e1e1e1;
    }
  }

  .alert {
    background: #0000000f !important;
    color: #fff !important;

    ul, li, p {
      font-family: bakh-light !important;
    }
  }

  .loadingAll {
    background: rgba(70, 76, 110, 0.1);
  }

  .text-muted {
    color: #9898d5 !important;
  }

  .card-bank {
    .card_number {
      color: #ffffff;
    }
  }

  .card-table .btn, .card-bank .btn {
    background: #ffffff0d !important;
    border: 1px solid #4e5c75;
    color: #b3c3d2 !important;

    &:hover {
      background: $primary !important;
      border: 1px solid darken($primary, 5%);
      color: #111 !important;
    }
  }

  .card-bank {
    .card-bank .sheba {
      color: #758b9e;
    }

    img {
      background: #bcc6d5;
      border-radius: 10px;
    }
  }


  .card-vip-lc .items .item {
    border-bottom: 1px solid #44446c;
  }

  .service-bold {
    background: linear-gradient(135deg, #1f2f51 0%, #31385c 100%);
  }

  .services-items-box .card-order-item .title {
    color: #ebecf5;
  }

  .services-items-box .card-order-item:hover {
    background: #474e6c;
  }

  .services-new {
    background: linear-gradient(135deg, #2e3353 10%, #20274b 50%);

    h6 {
      color: #ffffff;
    }
  }

  .btn-pr-qty {
    background: #eaf1f56e !important;
  }

  #box_info_prices {
    border-top: 2px solid #c8d4e336 !important;
    /* border-bottom: 2px solid #c8d4e336 !important; */

    .loading {
      background: $d-card-bg;
    }

    .currency{
        color: $primary;

      .currency_price {
        color: $primary !important;

      }
    }
    .prices {
      color: #fff !important;
    }
     .total {
      border-top: 2px dotted #e4ebf329 !important;
    }

  }

  label {
    color: #acb5c8 !important;
    font-family: 'bakh-light';
  }

  .help-block {
    color: #6e7f94;
  }

  .btn-default {
    background: rgb(35 39 72);
    color: #bdc6cf !important;
    box-shadow: 1px 3px 10px rgb(0 0 0 / 18%);
    border: 1px solid #2e3258;
  }

  .table th, .table td {
    border-top: 1px solid #eeeeee1a;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #f0f0f014;
  }

  .table{
    background: $d-card-bg;
  }

  .modal-content {
    .table{
      background: none !important;
    }
  }

  .table thead th {
    border-bottom: 1px solid #e7e7e724;
  }

  .comments .comment .comment-body {
    background: $d-card-bg;
    color: #d3d9eb;
  }

  .comments .comment .comment-body:before {
    border-left: 10px solid $d-card-bg;
  }
  .user-message {
    background: rgb(173 195 218 / 5%);
  }

  .empty-list {
    background: $app-color;
    background: linear-gradient(180deg, $d-card-bg 0%, $app-color 100%);

  }

  .btn-t{
    background: $primary;
    color: #333;

    &:hover,&:active{
      background: darken($primary,5%);
      color: #111 !important;
    }
  }

  .comments .comment .date {
    color: #94afc0bd;
  }

  .referral-code {
    background: linear-gradient(45deg, #4A517D 33%, #233b56 100%, transparent) !important;
  }

  .skelete-loading .tablel .roww {
    border-bottom: 1px solid #313552;
  }

  .skelete-loading .tablel .head {
    background: #2a3054;
  }

  .digital-prices .item {
    border-bottom: 0.3px solid #58667836;
    color: #d7dbe6;
  }

  .digital-prices .header {
    border-bottom: 1px solid #0c0c13;
    color: #ffffff;
  }

  .digital-prices input {
    border: 1px solid rgb(0 0 0 / 48%);
    box-shadow: 0 0 10px rgb(0 0 0 / 17%) inset;
    color:$primary;
  }
  .pagination .page-link {
    color: #c3cbd4;
    background-color: #ffffff29;
    border: 1px solid #eef2fa17;
  }

  .n-app-sidebar .close {
    background: #4e4e6c;

  }

  .app-events {
    background: $d-sidebar;
  }

  .app-events .dropdown-events ul.nav li a:hover, .app-events .dropdown-events ul.nav li a.active {
    background-color: #e9c500 !important;
    color: #000 !important;
    border: none !important;
  }


  .app-events .dropdown-events ul.nav li a {
    color: #ebebeb !important;
  }

  .app-events .notification .notification__item-details .notification__item-title {
    color: #c3c7dc;
  }

  .app-events .notification .notification__item {
    border-bottom: 1px dotted #eeeeee3b;
  }

  .app-events .notification .notification__item:hover {
    background: #f5f5f50d;
  }

  .nav-tabs {
    border-bottom: 1px solid #dee2e629;
  }

  .box-app-events {
    background: rgb(5 12 35 / 89%);
  }

  .togglemenu-over {
    background: rgb(4 8 23 / 81%);
  }

  .empty-list i {
    color: #dadada36;
  }

  .empty-list .title {
    color: #cdcdcd63;
  }

  .page-title {
    color: #fff;
  }

  .card-vip-lc .items .item .value {
    color: #ffffff;
  }

  .sweet-alert {
    background: #2e2e3f !important;
  }

  .ticket-info .info div {
    color: #ebecf5;
  }

  .ticket-info .info ,.order-show .info {
    color: #9bacc3;
  }

  .order-show .invoices-box h5 {
    color: #ffffff !important;
  }

  .order-show .invoices-box .invoices {
    border: 1px dashed #c4cfde59;
  }
  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e62b;
  }

  .card-credit {
    border: 1px solid #151a37;
    background: #4f546b;

    &.active, &:hover {
      border: 1px solid #ffd900 !important;
      background: #2c3144 !important;
    }
  }

  .order-crypto-coins {
    background: rgb(0 0 0 / 0%);
    border: 1px solid #a3a2a254;
    background-image: linear-gradient(to right, #f9f9f914 0%, #5b90cf24 51%, #1368bd00 100%);
  }

  .order-crypto-coins .title small {
    color: #819aba;
  }

  .incc {
    background: #d3d7ed4d !important;
    color: #ffffff !important;
  }

  .coins-box .order-crypto-coins-list {
    color: #ffffff;

  }

  .coins-box .order-crypto-coins-list {
    background: rgb(0 0 0 / 8%);
    color: #fff;
  }

  .ex-active {
    background: #118bbb !important;
    color: #fff !important;
  }

  .loader {
    background: $d-card-bg;

  }


.table{
  .btn-default {
    background: #86889f2e;
  }
}

  .card .card-header {
    background: #e6ecf71a;
    border-bottom: 1px solid #d8dee90d;
  }

  .skelete-loading .base::before , .base-skelete:after {
    opacity: 0.2;
  }

  .base-skelete{
    background: #2b304f !important;
  }

  .vip-layer-table .table a {
    color: #a6b6c8;
    font-family: 'bakh-light';
  }



  @media (max-width: 768px)
  {

    .services_items_box.active {
      background: #292c48;
    }

    .services_items_box .card-order-item {
      border: 1px solid #445464;
    }
  }

  .overlay-services {
    background: rgb(5 14 36 / 90%);
  }
}
