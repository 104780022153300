.n-app-main{
  .top-header{
    height: 45px;
    background: rgba(110, 139, 167, 0.08);
    border-radius: 0 0 20px 20px;
    margin-bottom: 12px;
    padding: 10px 19px;

    @media (max-width: 1000px) {
      margin-bottom: 30px;
      border-radius: 0 0 6px 6px;
    }

    @media (max-width: 768px) {
      margin: 0 -15px;
      margin-bottom: 30px;
      border-radius: 0 0 6px 6px;
      padding: 10px 15px;
    }

    .toggle-menu{
      border: none;
      background: $primary;
      padding: 4px 6px;
      margin-left: 11px;
      border-radius: 6px 0 6px;
      margin-right: -9px;
      cursor: pointer;

      &:hover{
        background: darken(   $primary,10%);
      }
    }

    .phone{
      direction: ltr;
      font-size: 17px;
      font-family: 'bakh-black';
      color: $app-color;



      svg{
        height: 20px;
        width: 20px;
        margin-right: 9px;
        fill: $app-color;
      }

      @media(max-width: 768px){
        margin-top: 2px;
        font-size: 14px;
        svg{
          height: 17px;
          width: 17px;
        }
      }

    }

    .events{
      cursor: pointer;
      position: relative;
      background: rgb(168 187 220 / 26%);
      padding: 5px;
      border-radius: 50%;

      &:hover{
        svg{
          stroke: $t-color;
        }

      }
      svg{
        height: 19px;
        width: 19px;
        stroke: #3f4d60;
      }

      .badge{
        position: absolute;
        right: 24px;
        border-radius: 5px;
        font-size: 10px;
        font-family: 'bakh-light';
      }

      .noti-icon-badge{
        position: absolute;
        top: -5px;
        right: -4px;
      }
      .nav-link{
        padding: 0;
        margin: 0;
      }
    }

    .dropdown-events {

      .head {
        margin: -8px 0px;
        background: $main-toolbar-sub-bg;
        margin-bottom: 0px;
      }

      .nav-tabs {
        border-bottom: 1px solid #464f52;
        margin: 0;
        padding: 0;

        .nav-link.active {
          color: #22cc76 !important;
        }
      }

      .head__title {
        font-size: 15px;
        color: #ffffff;
        padding: 12px;
      }

      .notification .notification__item:first-child {
        border-top: none;
      }
    }

    .notification .notification__item {
      border-top: 1px dotted #494e4e;
    }

    .dropdown-menu.show {
      display: block;
      text-align: right;
      background: #020a31;
      color: #f2f2f2;
      border: 1px solid #020a31;
    }
  }
}

.full-loading{
  display: flex;
  background: rgb(9 10 65);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
}

.togglemenu-over{
  background: rgba(0,0,0,0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}

$event-side-width : 350px;

.box-app-events{
  position: fixed;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 15;
  background: rgba(0,0,0,0.3);
}

.app-events {
  background: #fff;
  width: $event-side-width;
  height: 100%;
  position: fixed;
  left: -$event-side-width;
  top: 0;
  padding: 18px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 16;
  transition: 0.3s ease;

  .dropdown-events {

    ul.nav{
      text-align: right;
      justify-content: right;
      padding: 0;
      background: none;
      margin: -17px;
      margin-bottom: 10px;

      li{
        a{

          font-size: 12px;
          padding: 12px 15px !important;

          padding: 12px 15px !important;
          font-family: 'bakh-bold';
          border: none;
          color: #666 !important ;
          margin-left: 5px;
          .badge {
            padding: 6px 5px !important;
            display: inline-block;
            margin: 0;
            line-height: 3px;
            font-family: system-ui;
            font-weight: 300;
            border-radius: 50px;
            margin-bottom: -7px;
            margin-top: -21px;
            margin-right: 4px;
            font-size: 10px !important;
          }

          &:hover, &.active {
            background-color: #4b4b4b  !important;
            color: #fff !important ;
            border: none !important;
          }
        }
      }
    }

    .tab-content {
      font-size: 12px;
    }
  }

  .notification {
    padding: 3px;

    .notification__item {
      border-bottom: 1px dotted #eee;
      padding: 7px 0;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: #f5f5f5;
      }
    }


    .notification__item-icon {
      i {
        font-size: 15px;
      }

      svg {
        height: 35px;
        width: 35px;
        fill: #aaa;
      }
    }

    .notification__item-details {

      .notification__item-title {
        font-size: 11px;
        color: $app-color;
        font-family: 'bakh-light';
      }

      .notification__item-time {
        color: $dark-3;
        font-size: 9px;
      }
    }

    .view-all {
      background: #fff;
      display: block;
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px;
      right: 0;
    }
  }

  &.show{
    left: 0;
  }

}
