.bw-table {

    thead{
      @media(max-width: 768px){
        display: none;
      }
    }

    td, th {
      text-align: left;
    }

    th.hide {
      display: none;
    }

    td {
      @media(max-width: 768px){
        border-bottom: none;
      }
    }

    tr {
      width: 100%;

      @media(max-width: 768px) {
        display: block;
        border-bottom: 1px solid #d8e1ef;
        padding: 10px;
        background: #fefeff;
        margin-bottom: 5px;

        td {
          display: block;
          padding: 5px 0;
          border: none !important;
        }
      }
    }

    th {
      @media(max-width: 768px){
        border-bottom: none;
      }
    }

    .n-title {
      display: none;
      @media (max-width: 768px) {
        display: inline-block;
      }
    }

    .no-n-title{
      .n-title {
        display: none !important;
      }
    }

  th{
    border-bottom: none !important;
  }
  tbody {
    border-top: none !important;

  }
  tfoot {
    td{
      background: #eef6fd;
    }
  }
  }
