#withdraw-request{
  .w-title{
    color: #6881b4;
  }
  .radio-container-coin {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 24px;
    width: 100%;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 10;
      &:checked ~ .coin {
        @media(max-width: 768px){
          background: #042973;
        }
        background: #fff;
        color: $tree-color;
        .name{
          background: #fff;
          color: $tree-color;
          svg{
            fill: $tree-color !important;
          }
        }
        .balance{
          color: $tree-color;
          span{
            color:  $tree-color;
          }
        }
        .no_wallet{
          color: $tree-color;
        }
      }
    }

    .coin{
      border: 1px solid #6881b4;
      border-radius: $border-radius;
      margin-bottom: 15px;
      overflow: hidden;
      @media(max-width: 768px){
        display: block !important;
        text-align: center;
        border: 1px solid #334c7d;;
      }
      .name,.balance , .wallet ,.no_wallet{
        float: left;
        padding: 10px;

        @media(max-width: 768px){
          float: none;
          width: 100%;
          border: none !important;
          margin-bottom: 5px;
          padding: 0 10px;
          word-break: break-all;
        }
      }

      .name{
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        border-right: 1px solid #6881b4;
        padding-right: 20px;
        margin-right: 20px;
        min-width: 130px;
        @media(max-width: 768px){
          padding: 10px !important;
          background: rgba(255,255,255,0.2);
        }
      }

      .balance{
        font-size: 12px;
        color: #83a1cd;
        margin-right: 10px;

        span{
          font-weight: 800;
        }
      }

      .no_wallet{
        color: #6881b4;
        text-decoration: underline !important;
      }

      .wallet{
        font-size: 12px;
        color: #6881b4;
        @media(max-width: 768px){
          font-size: 10px;
          line-height: 22px;
        }
      }

    }

  }

  .make-withdraw{
    color: #6881b4;

    .btn{
      height: 50px;
    }
  }
}

#bw-deposit{

  .deposit{
    .date{
      background: #ebf1ff;
      padding: 10px;
      color:#6881b4;
    }

    .card-body{
      color: #163c91;
      font-size: 10px;

      .amount{
        width: 100%;
        font-weight: 800;
        font-size: 16px;
        span{
          font-weight: 400;
          font-size: 13px;
          margin-left: 5px;
          color: $color-light-panel;
        }
      }

      img,svg{
        float: right;
        margin-top: 5px;
        max-width: 100%;
      }
    }
  }



}