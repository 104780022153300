
#investment{


  .description{
    font-size: 14px;
    color: #6881b4;
    text-align: center;
    width:700px;
    max-width: 100%;
    margin: 0 auto;
  }

  #liveprice{
    padding: 0 20px;
    overflow: hidden;

    @media(max-width: 1000px){
      padding: 0 30px;
    }

    [class*=' col-']{
      padding: 0 !important;
    }

    .radio-container-price {
      display: inline-block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 24px;
      width: 100%;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 100%;
        width: 100%;
        z-index: 10;
        &:checked ~ .box {
          color: $tree-color;
          background: #fff;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.21);
          border: 2px solid $base-color;
          &:before{
            display: none;
          }

          .rs{
            display: block;
          }

          svg{
            fill:$base-color;
          }
        }
      }
      .box{
        color: #c8cdd8;
        font-weight: 800;
        padding: 7px 14px;
        position: relative;
        border: 2px solid transparent;
        border-radius: $border-radius;

        @media(max-width: 1000px){
          border: 1px solid #183775;
          padding-left: 30px;
        }
        &:before{
        display: none;

          @media(max-width: 1000px){
            display: none;
          }
        }

        span{
          font-weight: 300;
        }

        @include transition(0.3s ease);

        svg{
          fill: #96a6c8;
          @include transition(0.3s ease);
        }

        &:hover{
          color: #fff;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.21);
          &:before{
            display: none;
          }

          svg{
            fill:$base-color;
          }
        }

        .rs{
          margin-top: 10px;
          position: relative;
          z-index: 11;
          display: none;
          .radio-container-2{
            line-height: 11px;
            font-size: 10px;
            padding-top: 2px;
          }
        }
      }

    }


    .col-6:last-child {
      .box{
        &:before{
          display: none;
        }
      }
    }

  }

  .calc{
    background: $tree-color;
    position: relative;
    max-width: 100%;
    border-radius: 0 0 9px 9px;

    .bottom{
      fill: $tree-color;
      fill: #001f5d;
      height: 100px;
      width: 100%;
      padding: 0;
      margin: 5px;
      margin-bottom: -81px;
      margin-left: 0px;

      @media(max-width: 1000px){
        margin-bottom: -74px;
        position: absolute;
        bottom: 14px;
        left: 0;
      }
    }


    .body{
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      padding-top: 20px;

      @media(max-width: 1000px){
        padding: 30px;
        width: 700px;
      }

      @include transition(0.3s ease);

      .title{
        color:  $color-light;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        @include transition(0.3s ease);
        margin: 20px auto;
      }

      .main{
        width: 50%;
        padding: 5px;
        margin: 0 !important;
      }

      .plans{
        box-shadow: none;

        .plan{
          float: left;
          width: 100%;

          .titr{
            text-align: center;
            color: #a5afc1;
            margin-bottom: 0px;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
          }

          .box{
            background: #fcfcfc;
            border-radius: 5px;
            border: 2px solid #e6e9ef;
            padding: 20px;
            text-align: center;

            @include transition(0.3s ease);

            @media(max-width: 600px){
              padding: 10px;
            }

            .a{
              width: 120px;
              border-right: 1px solid #ddd;

              @media(max-width: 560px){
                width: 89px;
              }
              @media(max-width: 400px){
                width: 100%;
                border: none;
              }
            }

            .profit{
              color: $color-light;
              font-size: 30px;
              text-align: center;
              font-weight: 800;
              margin-bottom: -5px;
              @include transition(0.3s ease);
              @media(max-width: 600px){
                font-size: 25px;
              }
              small{
                font-weight: 600;
                color: #b0bed8;
                margin-left: -10px;
              }
            }

            .description{
              color: $color-light;
              text-align: center;
              font-size: 12px;
              font-weight: 600;
              @include transition(0.3s ease);

              @media(max-width: 600px){
                font-size: 10px;
              }
            }
          }
        }

        .radio-container {
          display: inline-block;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          line-height: 24px;
          width: 100%;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 100%;
            width: 100%;
            &:checked ~ .active {
              cursor: pointer;
              .titr{
                color: $base-color;
                text-transform: uppercase;
              }
              .box{
                border: 2px solid $base-color;

                .profit{
                  color: $blue;
                }
                .description{
                  color: $tree-color;
                }
              }
            }
          }

          .active {
            cursor: pointer;
            .titr{
              color: #a5afc1;
            }

            .box{
              border: 2px solid #e6e9ef;

              .profit{
                color: $color-light;
                small{
                  color: #b0bed8;
                }
              }

              .description{
                color: $color-light;
              }
            }
          }
        }

        @include clearfix();
      }

      .inputs{

        padding: 5px;
        @media(max-width: 500px){
          margin-top: 10px;
        }

        label{
          color: $color-light;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          display: block;
          text-transform: capitalize;
        }

        .form-control{
          border: 2px solid #b2c8e4;
          background: #fcfcfc;

          &:focus {
            border-color: $base-color;
            box-shadow: 0 5px 12px rgba(255, 69, 21, 0.2);
            &::placeholder {
              color: $base-color;
              font-weight: 600;
            }

            &::-moz-placeholder {
              color: $base-color;
              font-weight: 600;
            }
          }
        }

      }
    }

  }


  .calculator-info{
    margin-top: 100px;
    @media(max-width: 500px){
      margin-top: 50px;
    }
    ul{
      padding: 0;
      display: block;
      text-align: center;
      list-style: none;

      li{
        display: inline-block;
        padding:5px 20px;
        text-align: left;
        @media(max-width: 500px){
          width: 95%;
        }
        border-right: 1px solid #c6d1e2;

        &:last-child{
          border-right: none;
        }

        @media(max-width: 768px){
          border: 1px solid #eee !important;
          background: #fff;
          margin: 5px;
        }

        .title{
          font-size: 14px;
          color: $blue;
          font-weight: 600;
          @media(max-width: 768px){
            font-size: 12px;
            text-align: center;
            margin-bottom: 5px;
          }
        }
        .amount{
          color: $tree-color;
          font-weight: 800;
          font-size: 18px;

          @media(max-width: 768px){
            font-size: 16px;
          }
          @media(max-width: 500px){
            text-align: center;
          }
          small{
            font-weight: 500;
            font-size: 16px;
            margin-left: 5px;
            @media(max-width: 768px){
              font-size: 16px;
            }
          }
        }
      }

    }
  }

}

#confirm-investment{
  .title{
    color: $tree-color;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .amount{
    background: #f6fdff;
    padding: 20px;
    color: #597fd1;
    font-size: 15px;
    font-weight: 600;
    word-break: break-all;

    .price{
      color: $base-color;
      font-weight: 800;

      @media(max-width: 768px){
        display: block;
      }
    }

    .currency{
      font-weight: 400;
    }
  }
  .plan-name{
    font-weight: 600;
    font-size: 20px;
    color: #6286d4;
    width: fit-content;
    margin: 20px auto;
    border-bottom: 1px solid #cdd9f3;
    padding: 10px;
  }

  .details{
    color: #6881b4;
    font-weight: 500;
    font-size: 16px;
    span{
      font-weight: 800;
      @media(max-width: 768px){
        display: block;
      }
    }
  }

  .address{
    background: #f6fdff;
    padding: 15px;
    color: #597fd1;
    font-size: 15px;
    font-weight: 600;
    word-break: break-all;
    margin-bottom: 20px;

    .wallet{
      font-size: 15px;
      font-weight: 600;
      color: $tree-color;
      display: inline-block;
      @media(max-width: 768px){
        font-size: 12px;
      }
    }
    .copy{
      background: #dee7f5;
      border: 1px solid #8297c1;
      padding: 3px 8px;
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      @media(max-width: 500px){
        margin-top: 10px;
      }
    }
  }

  .qr{
    text-align: center;
    color: #6881b4;
    font-size: 12px;
    font-weight: 400;

    img{
      margin: 0 auto;
      display: block;
      margin-bottom: 5px;
    }
  }

  .bg_none{
    @media(max-width: 500px){
      margin-top: -20px;
    }
  }
}

