$bw-search-col: 23%;
$bw-search-col-1: 100% - ($bw-search-col * 4)  ;

#bw-transaction{
  .b-col{
    width: $bw-search-col;
    padding-right: 8px;

    @media(max-width: 768px){
      width: 50%;
      padding: 0 2px !important;
    }

    label{
      color: #6881b4;
      font-size: 11px;
    }

    .btn-search{
      padding: 9px 0 !important;
      @media(max-width: 768px){
        margin-top: -20px;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .selects{

      background: #fff;
      padding: 5px;
      border-radius:$border-radius;
      box-shadow: 0px 3px 4.7px 0.3px rgba(80, 114, 182, 0.2);

      .w-100{
        width: 100% !important;
      }

      .select-box{
        position: relative;
        width: 33%;

        &:hover {
          &:after {
            border-color: $base-color;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 11px;
          right: 9px;
          border: solid #999cf3;
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 2px;
          font-weight: 100;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          z-index: 1;

          @media (max-width: 768px) {
              right: 4px;
          }

          @include transition(0.3s ease);

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: none;
          }

        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; /* remove default arrow */
          border: none;
          padding: 6px;
          height: auto;
          color: $tree-color;
          font-weight: 600;
          font-size: 11px;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            padding: 3px 9px ;
          }
        }
      }

      .b-r{
        border-right: 1px solid #eee !important;
        @media (max-width: 768px) {
          border-right: none !important;
        }
      }

    }
  }
  .b-col-1{
    width: $bw-search-col-1 !important;
    padding-right: 0;
    @media(max-width: 768px){
      width: 100% !important;
      padding: 0 2px !important;
    }
  }

  .balance{
    background: $tree-color;
    padding: 15px;
   color :$color-light-panel;
    color: #d2e0fc;
    font-size: 14px;

    @media(max-width: 768px){
      display: block !important;
    }
    span{
      font-weight: 400;
    }
    .b-c{
      width: 50%;
      text-align: center;
      @media(max-width: 768px){
        width: 100%;
      }
    }
    .b-c-r{
      border-right: 1px solid #394c66;
      @media(max-width: 768px){
        border-right: none;
        border-bottom: 1px solid #394c66;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .table
  {
    font-family: $font-family-sans;

    background: #d7e3fd;
    tr:nth-child(odd) {
      background: #e1eafc;
    }
    td{
      font-size: 12px;
      border-top: none !important;
      font-family: $font-family-sans;

      a.verify{
        background: #e1eafc;
        display: inline-block;
        font-size: 11px;
        padding: 3px 5px;
        color: $tree-color;
        border-radius: 2px;
        cursor: pointer;
        font-weight: 700;
        border: 1px solid #d0dcf4;
        &:hover{
          background: $tree-color;
          color: #e1eafc;
        }
      }
    }

    th{
      background: #e2f0fc;
      color: #001f5d;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
    }

  }

}


.checkout-box{
  font-size: 12px;
  font-family: $font-family-sans;
  font-weight: 200;
  margin-bottom: 4px;

  h4{
    font-size: 24px;
    font-family: $font-family-krub-heavy;
  }

  table{
    font-family: $font-family-sans;
    td,th{
      font-family: $font-family-sans;

    }
  }

  .invoice-total{

    .title{
      font-size: 17px;
      color: $t-color;
      font-family: $font-family-krub-heavy;
    }

    .price{
      font-family: $font-family-krub-heavy;
      font-size: 17px;
      color: $t-color;
    }
  }
}

