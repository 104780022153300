// account balance
.dashboard-account {

  background-size: cover;

 .level{
   font-size: 19px;
   font-family: 'bakh-black';
   color: #93c1d6;

   @media(max-width: 768px){
     font-size: 11px;
   }
 }


  .img-level{
    @media(max-width: 768px){
      height: 40px;
    }
  }

  .name{
    font-family: 'bakh-bold';
  }


  .CircularProgressbar{
    .CircularProgressbar-path {
      stroke: $t-color;
      stroke-linecap: round;
      transition: stroke-dashoffset 0.5s ease 0s;
    }
  }



}

.card-ads {

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 30px;

  @media(max-width: 768px) {
    padding: 20px;
    text-align: center;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    color: $tree-color;
  }

  p {
    font-size: 12px;
    color: #597fd1;
    font-weight: 500;
  }

  .btn {
    background: $yellow;
    color: $tree-color;
    font-weight: 700;
    font-size: 14px;
    padding: 10px 20px;
    @media(max-width: 768px) {
      float: none !important;
      margin: 0 auto;
    }
  }

}

.marketing-banners {

  .btn-banners {
    color: $tree-color;
    font-weight: 600;
  }

  .referral-link {
    background: #f5faff;
    color: #6881b4;
    text-transform: capitalize;

    span {
      text-transform: lowercase;
      color: #aab9d7;
      margin: 0 20px;

      @media(max-width: 768px) {
        display: block;
        width: 100%;
        margin: 0;
        margin-bottom: 0px;
        word-break: break-all;
        margin-bottom: 6px;
      }
    }

    padding: 10px 20px;

    button {
      background: #fff;
      color: #6881b4;
      padding: 2px 7px;
      border: none;

      &:hover {
        background: $tree-color;
        color: #fff;
      }
    }
  }

  .m-banner-link {
    text-decoration: underline !important;
    color: #597fd1;
    text-transform: capitalize;
  }
}

#liveprice {
  padding: 0 20px;
  overflow: hidden;

  @media(max-width: 1000px) {
    padding: 0;
  }

  [class*=' col-'] {
    padding: 0 !important;
  }

  .box {
    color: #415886;
    font-weight: 800;
    padding: 40px 20px;
    position: relative;
    @media(max-width: 1000px) {
      border: 1px solid #e6ecf7;
      padding-left: 30px;
    }

    &:before {
      content: '';
      height: 80%;
      width: 1px;
      background: #e6ecf7;
      position: absolute;
      right: 0;
      top: 10%;

      @media(max-width: 1000px) {
        display: none;
      }
    }

    .icon {
      font-size: 12px;
      font-weight: 500;
      color: #96a6c8;
      line-height: 20px;

      svg {
        width: 20px;
        height: 20px;
        fill: #96a6c8;
        margin-right: 2px;
        display: inline-block;
        margin-bottom: 3px;
      }

      margin-bottom: 5px;
    }

    @include transition(0.3s ease);


    &:hover {
      color: #001f5d;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.21);

      &:before {
        display: none;
      }

      svg {
        fill: $blue;
      }
    }
  }

  .col-6:last-child {
    .box {
      &:before {
        display: none;
      }
    }
  }

}

.dash-access {
  background: none;
  padding: 20px 9px 10px;
  box-shadow: none !important;



  .link {
    text-align: center;
    font-size: 13px;
    padding: 17px 5px ;
    border-radius: 6px;
    text-decoration: none;
    margin-bottom: 0;
    overflow: hidden;
    z-index: 1;
    font-family: 'bakh-bold';

    img{
      height: 50px;
    }
    i{
      text-shadow: none;

    }


   svg{
       font-family: $font-family-krub-bold;
       height: 21px;
       width: 21px;
       margin: 0 auto;
       margin-bottom: 4px;
       fill: #fff;
   }

    &:before{
      content: "";
      width: 54px;
      height: 400px;
      transform: rotate(30deg);
      transition: 0.3s ease;
      left: -101px;
      background: rgb(119 193 242 / 9%);
      position: absolute;
      top: -25px;
      border-radius: 0 10px 0 0;
    }


  &:hover {
    &:before{
      left: 80px;
      top: -50px;
    }

    i {
      opacity: 1;
    }
  }

  i {
    font-size: 20px;
    margin-bottom: 5px;
    opacity: 0.7;
  }

  margin: 0 -9px;
  @media(max-width: 1000px) {
    margin: 0 0;
    margin-bottom: 11px;

    padding: 15px 0;
  }

  @media(max-width: 768px) {
    padding: 6px 0 !important;
    font-size: 9px !important;
  }
}


}

.dashboard-rr {
  .balance {
    font-size: 18px;
    font-weight: 800;
    font-family: 'bakh-black';
    color: #4A517D;

  }

  .card{
    .card-title{
      color: #aaa;
    }
  }

  svg{
    height: 50px;
    width: 50px;
  }

  &.chart-data{
    height: 350px;
    canvas{
      width: 100%;
      height: 100%;
    }
  }

  .go-link {
    a {
      padding: 8px 13px;
      border-radius: 50px;
      font-size: 12px;
      font-family: 'bakh-bold';
      color: #8793a6;

      background: none;
      border: 1px solid #cfcfcf;

      &:hover {
        color: $t-color;
      }
    }
  }

  &.medium{
    padding: 11px;
    svg {
      height: 24px;
      width: 24px;
    }


   .card-title {
        color: #959dd6 !important;
        font-size: 13px !important;
        font-family: "bakh-bold" !important;
        margin-bottom: 0;
        letter-spacing: 0.2px;
        text-shadow: none;
        margin-bottom: 6px;
    }


    .balance {
      font-size: 15px;
    }

  }
}

fieldset{
  border: 2px dashed #959abe73;
  padding: 20px;
  border-radius: 12px;

  legend{
    width: fit-content;
    padding: 0 10px;
    font-size: 14px;
  }
}


.card{
  .items{
    margin-top: 10px;

    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #959dd6;
      border-top: 1px dotted #d0d9e3;
      padding: 7px;

      .value{
        color: #505787;
        font-family: bakh-black;
      }
    }
  }
}