.cf-mask {
  background: rgba(5, 13, 58, 0.96);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: none;
  overflow-y: auto;
}

.cf-mask.active {
  display: block;
}

/****** Auth mask *****/

.cf-auth-mask {
  background: rgba(5, 13, 58, 0.96);
}

.cf-mask-wrapper{

  .head {
    padding: 20px 0;
    color: #fff;
    position: relative;
    img{
      @media(max-width: 500px){
        display: none;
      }
      float: left;

    }
    .pr {
      ont-size: 14px;
      margin: 4px 15px;
      font-weight: 200;
      float: left;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      float: left;
    }

    .close-mask {

      position: absolute;
      width: 30px;
      height: 30px;
      right: 0;
      top: 15px;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &:after, &:before {
        content: '';
        color: #fff;
        opacity: 0.9;
        height: 2px;
        width: 100%;
        background: #fff;
        position: absolute;
        top: 20px;
        @include transform(rotate(45deg));
      }

      &:after {
        left: 0;
      }
      &:before {
        right: 0;
        @include transform(rotate(-45deg));

      }

    }

    &:after{
      content: '';
      clear: both;
      display: block;
    }
  }

  /****** panel *****/
  .panel{
    .head {
      padding: 31px 0;
    }

    .main {

      width: 500px;
      margin-left: 0 !important;
      margin: 80px auto !important;

      @media (max-width: 768px) {
        width: 90%;
        margin: 30px auto;
      }

      .body {
        background: #f7faff;
        min-height: 200px;
        padding: 30px;
        border-radius: $border-radius;
        box-shadow: 0 4px 0 rgba(255,255,255,0.3);
        .body-title{
          .icon{
            svg{
              fill: #8d9ab3;
            }
            margin-right: 8px;
            float: left;
          }

          .text-clp{
            font-size: 12px;
            margin: 1px 0px;
            line-height: 10px;

            @media (max-width: 768px){
              font-size: 10px !important;
            }
          }

          .text{
            color:$tree-color;
            float: left;
            font-size: 16px;
            font-weight: 600;
            width: 84%;
          }

          &:after{
            content: '';
            clear: both;
            display: block;
          }
        }

        img{
          max-width: 100%;

          @media (max-width: 500px) {
            margin-top: -38px;
          }
        }

        .btn{
          @media (max-width: 500px) {
            font-size: 12px;
          }
        }

        @media (max-width: 768px) {
          .cf-btn-primary {
            padding: 15px auto;
          }
        }

      }


    }

  }

  /****** Auth *****/
  .all{
    position: relative;

    .main {

     .close-mask {

        position: absolute;
        width: 20px;
        height: 20px;
        right: 42px;
        top: 26px;
        cursor: pointer;
        opacity: 0.8;

        @media(max-width: 768px){
          top: 10px;
          right: 15px;
        }

        &:hover {
          opacity: 1;
        }

        &:after, &:before {
          content: '';
          color: #fff;
          opacity: 0.9;
          height: 3px;
          width: 100%;
          background: #c6c6c6;
          position: absolute;
          top: 20px;
          @include transform(rotate(45deg));
        }

        &:after {
          left: 0;
        }
        &:before {
          right: 0;
          @include transform(rotate(-45deg));

        }

      }

      position: relative;
      width: 800px;
      margin: 80px auto;
      max-width: 90%;

      @media (max-width: 768px) {
        width: 90%;
        margin: 30px auto;
      }

      .body {

        background: #fff;
        min-height: 200px;
        padding: 80px 0 20px;
        padding-bottom: 30px;
        border-radius: $border-radius;

        @media(max-width:768px){
          padding-bottom: 200px;
        }

        .container{
          width: 80%;
          @media(max-width:768px){
            width: 100%;
          }
        }

        @media (max-width: 768px) {
           padding: 50px 0;
          .cf-btn-primary {
            padding: 15px;
          }
        }

        .body-main {
          width: 80%;
          margin: 0 auto;
        }

      }

      .footer{
        position: relative;
        width: 100%;
        background: #0e2666 ;
        background-blend-mode: color-dodge;
        min-height: 150px;
        color: #fff;
        padding: 46px 0;

        &:before{
          content: '';
          background:#0e2666  ;
          background-blend-mode: color-dodge;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right center;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0.1;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-size: cover;
            opacity: 0.1;
          }
        }

        .title{
          font-weight: 700;
          font-size: 15px;

          svg{
            height: 17px;
            width: 17px;
            fill: #fff;
          }
        }
      }
    }
  }

  /****************/

  .modal-message{
    .message{
      margin-top: -40px;
      font-size: 20px;
    }
    .messages{
      text-align: justify;
      p{
        color: $tree-color;
        font-size:14px ;
        strong{
          color: $danger;
          font-weight: inherit;
        }
      }
    }
  }

}