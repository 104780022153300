$card-padding: 25px;

.card {
    border-radius:$border-radius;
    margin-bottom: 20px;
    background: #fff;
    border: none;
    color: $app-color;
    border-radius: 15px;

    box-shadow: 1px 1px px rgb(143 143 143 / 20%);
    box-shadow: 0px 2px 3px rgba(92, 104, 160, 0.15) !important;

    .component-loader{
        background: linear-gradient(
                        180deg, darken($card-bg,5%) 0%, transparent 100%);
    }

    .card{
        box-shadow: none;
    }

    .header-title{
        font-size: 15px;
        color: #fff;
    }

    .title-icon {
        margin-top: 7px;

        .icon {
            svg {
                fill: #8d9ab3;
            }
            margin-right: 8px;
            float: left;
        }

        .text-clp {
            font-size: 12px;
            margin: 7px 0;
            line-height: 10px;
            font-weight: 500;
            color: #6881b4;
            @media (max-width: 768px) {
                font-size: 10px !important;
            }
        }

        .text {
            font-size: 18px;
            color: rgb(89, 127, 209);
            font-weight: 700;
            width: calc(100% - 50px);

        }

        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }

    .card-header{
        font-weight: 700;
        background: rgb(230 236 247);
        border-bottom: 1px solid #d8dee9;
        margin: -25px;
        margin-bottom: 21px !important;
    }

    .card-title {
        color: lighten($app-color,3%);
        font-size: 15px !important;
        font-family: "bakh-black" !important;
        margin-bottom: 0;
        letter-spacing: 0.2px;
        text-shadow: none;

        @media(max-width: 768px) {
            font-size: 14px !important;
            font-weight: 500;
        }
    }
}

.dark{
    border-radius: $border-radius;
    background-color: $tree-color;
    box-shadow: 0px 3px 7px 0px rgba(104, 129, 180, 0.15);
    min-height: 200px;

    .header{
        padding: 40px;
        @media(max-width: 768px){
            padding: 20px;
        }
        .icon{
            svg{
                width: 30px;
                height: 30px;
                fill: rgb(245, 250, 255);
                margin-top: -6px;
            }
            margin-right: 10px;
            float: left;
        }

        .title{
            float: left;
            font-size: 16px;
            color: rgb(245, 250, 255);
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.1px;

            span{
                color: $primary;
            }
        }

        @include clearfix();
    }

    .amount{
        padding: 10px 40px;
        @media(max-width: 768px){
            padding: 10px 20px;
        }
        background: #597fd1;
        color: #f5faff;

        .title{
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            margin-top: 5px;
            text-transform: capitalize;
            @media(max-width: 768px){
                margin-top: 0;
            }
        }

        .price{
            font-size: 18px;
            font-weight: 800;
            text-align: center;
            @media(max-width: 1200px){
                font-size: 16px;
            }

            span{
                font-weight: 500;
                margin-left: 5px;
            }
        }
    }

    .titr{
        color: #98aad9;
        font-size: 12px;
        font-weight: 600;
    }

    .in-amount{
        color: #5f74ab;
        font-size: 17px;
        font-weight: 700;
        @media(max-width: 1200px){
            font-size: 14px;
        }
        span{
            font-weight: 400;
            margin-left: 5px;
        }
    }

    .date{
        color: #5f74ab;
        font-size: 10px;
        font-weight: 600;
        @media(max-width: 768px){
            font-size: 9px !important;

        }
    }
}

.yellow{
    .header{
        .icon{
            svg{
                fill: $yellow;
            }
        }

        .title{
            color: $yellow;
        }

        @include clearfix();
    }
    .amount{
        background: $yellow;
        color: $tree-color;
    }
}

.card-body {
    padding: $card-padding;
    @media (max-width: 768px) {
        padding: 15px !important;
    }
}

.card-header {
    font-weight: 700;
    margin-bottom: 20px;
    background: rgba(128, 126, 126, 0.1);

    .icon {
        height: 38px;
        width: 48px;
        text-align: center;
        padding: 6px;
        display: block;
        border-radius: 3px;
        margin-right: 20px;

        svg {
            display: block;
            height: 26px;
            width: 37px;
            margin: 0px auto;
            fill: #415152;
        }
    }
    .title {
        font-weight: 400;
        margin: 5px 0;
    }
}

@each $color, $value in $theme-colors {
    .card-header-#{$color} {
        color: #fff;
        @include gradient-bg($value);
    }
}

@each $color, $value in $theme-colors {
    .card-header-gradiant-#{$color} {
        color: #fff;
        @include gradient-x($value, lighten($value, 10%));
    }
}
