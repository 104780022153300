//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border:none;
  @include border-radius($alert-border-radius);
  font-weight: 600;
  padding-left: 51px;
  border-radius: 10px;
  box-shadow: none !important;
  //margin-bottom: 31px;
  overflow: hidden;


  @media(max-width: 768px){
    margin-bottom: 15px !important;
  }

  line-height: 23px !important;
  box-shadow: none !important;
  border: 1px solid rgba(255,255,255,00.1);
  font-family: 'bakh-light' !important;
  text-align: justify !important;
  color: $app-color !important;

  .title-c{
    color: $app-color;
    font-size: 14px;
    font-family: $font-family-sans;
    margin-bottom: 5px;

    @media(max-width: 768px){
      font-size: 12px;
      font-weight: 500;
    }
  }

  svg{
    height: 20px;
    width: 20px;
    margin-right: 10px;
    position: absolute;
    left: 18px;
  }

  ul{
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: ($close-font-size + $alert-padding-x * 2);

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(rgb(255 255 255 / 0%), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
  }
}
