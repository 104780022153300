@font-face {
  font-family: 'bakh-reqular';
  font-style: normal;
  src:
  url('/assets/fonts/bakh/woff/YekanBakhFaNum-Regular.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/assets/fonts/bakh/woff2/YekanBakhFaNum-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'bakh-light';
  font-style: normal;
  src:
  url('/assets/fonts/bakh/woff/YekanBakhFaNum-Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/assets/fonts/bakh/woff2/YekanBakhFaNum-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'bakh-bold';
  font-style: normal;
  src:   url('/assets/fonts/bakh/woff/YekanBakhFaNum-Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/assets/fonts/bakh/woff2/YekanBakhFaNum-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'bakh-black';
  font-style: normal;
  src:  url('/assets/fonts/bakh/woff/YekanBakhFaNum-Black.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/assets/fonts/bakh/woff2/YekanBakhFaNum-Black.woff2') format('woff2');
}
@font-face {
  font-family: 'bakh-extrabold';
  font-style: normal;
  src:  url('/assets/fonts/bakh/woff/YekanBakhFaNum-ExtraBold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/assets/fonts/bakh/woff2/YekanBakhFaNum-ExtraBold.woff2') format('woff2');
}


@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/eot/robotolight.eot');
  src: url('../../fonts/eot/robotolight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../../fonts/woff2/robotolight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../../fonts/woff/robotolight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../../fonts/ttf/robotolight.ttf') format('truetype');
}
@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/eot/robotoregular.eot');
  src: url('../../fonts/eot/robotoregular.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../../fonts/woff2/robotoregular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../../fonts/woff/robotoregular.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../../fonts/ttf/robotoregular.ttf') format('truetype');
}