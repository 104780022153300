$side-width : 300px;

.n-app-sidebar{
  background: #fff;
  width: $side-width;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 18px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    background: $bg;

  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #d9dae5;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d2d4e0;
  }

  @media(max-width: 1000px){
    right: -$side-width ;
    transition: 0.3s ease;
  }

  .close{
    position: absolute;
    left: 0;
    /* border: 1px solid #666; */
    background: #d6d6e2;
    border-left: none;
    border-radius: 0 15px 15px;
    line-height: 0;
    padding: 8px;
    top: 0px;
    color: #000;
  }

  .caferazimg{
    margin: 0 auto;
    display: block;

    @media(max-width: 768px){
      display: none;
    }
  }

  .verifications{
    width: 100%;
    /* background: #eff2f799; */
    padding: 7px;
    text-align: center;
    /* border-radius: 50px; */
    border-top: 1px solid #27375424;
    /* margin: -4px; */
    border-radius: 8px;
    border-bottom: 1px solid #27375424;
  }

  .user-info{
    .avatar{
      border-radius: 50%;
      height: 60px;
      width: 60px;
    }

    .name{
      font-size: 14px;
      font-family: 'bakh-bold';
    }
  }

  // left sidebar
  .left-sidebar {

    @include transition(0.3s ease);

    //.theiaStickySidebar {
    //  @media(max-width: 768px) {
    //    height: 100% !important;
    //    position: initial !important;
    //    width: auto !important;
    //  }
    //}

    .sidebar {

      .menu {

        ul {
          padding: 0;

          li {

            list-style: none;

            a {
              display: block;
              padding: 5px 0;
              font-size: 13px;
              color: darken($app-color,10%);
              cursor: pointer;
              position: relative;
              font-family: 'bakh-bold';

              .badge{
                position: absolute;
                left: 22px;
                top: 16px;
                border-radius: 5px;
                font-weight: 100;
                line-height: 0;
                padding: 9px 5px 5px;
              }

              .text-danger {
                color: #ff5d43 !important;
              }

              @media (max-width: $sidebar-responsive-size) {
                padding: 5px 0;
              }


              &:hover {
                @include transition(0.3s ease);
                color: $t-color;

                .icon {
                  color: $t-color;
                  @include transition(0.3s ease);

                  svg {
                    color: $t-color;
                    @include transition(0.3s ease);
                  }
                }
              }

              .icon {
                float: right;
                height: 33px;
                width: 41px;
                text-align: center;
                display: block;
                border-radius: 3px;
                margin-left: 14px;

                svg {
                  display: block;
                  height: 18px;
                  width: 18px;
                  margin: 5px auto;
                  fill: #252727;
                }
              }
              .title {
                float: right;
                margin-top: 6px;
              }


              .i{
                float: right;
                margin-top: 1px;
                margin-left: 15px;
                font-size: 17px;
                background: rgba(226, 233, 247, 0.34);
                padding: 6px;
                border-radius: 6px;
              }

              .a-down{
                margin: 0;
                float: left;
                margin-top: -5px;
                position: absolute;
                left: 0;
                top: 17px;

                -webkit-transition: 0.2s ease;
                -moz-transition: 0.2s ease;
                -ms-transition: 0.2s ease;
                -o-transition: 0.2s ease;
                transition: 0.2s ease;

                svg{
                  -webkit-transition: 0.4s cubic-bezier(.11,.33,.53,.91);
                  -moz-transition: 0.4s cubic-bezier(.11,.33,.53,.91);
                  -ms-transition: 0.4s cubic-bezier(.11,.33,.53,.91);
                  -o-transition: 0.4s cubic-bezier(.11,.33,.53,.91);
                  transition: 0.4s cubic-bezier(.11,.33,.53,.91);
                  height: 15px;
                  width: 15px;
                }
              }

              &:after {
                content: '';
                display: block;
                clear: both;
              }
            }

             &.active {
              //@include transition(0.3s ease);
                > a{
                  .icon,.title {
                    color: #000;
                    @include transition(0.3s ease);
                  }

                  .i{
                    background: $primary;
                    color: #000;
                  }
                }

            }

          >  ul{

              &:before{
                content: '';
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom:10px solid rgba(226, 233, 247, 0.34);
                position: absolute;
                top: -10px;
                right: 27px;
              }

              padding: 7px 50px 13px 20px;
              margin: 10px -20px;
              background: #e2e9f745;
              border-radius: 0;

               li{
                 position: relative;

                 &:before{
                   content: "";
                   width: 10px;
                   height: 1px;
                   display: inline-block;
                   background: #8594b5;
                   position: absolute;
                   top: 14px;
                   right: -16px;
                 }
               }


              visibility: hidden;
              opacity: 0;
              position: absolute;
              transform: translateY(10px);

              a{
                padding: 0 30px;
                &:before {
                  right: 0px;
                  top: 16px;
                }
              }

            }


          }

          li.active{

            > a{
              color: $app-color;

              &:before{
                width: 15px !important;
                @media (max-width: $sidebar-responsive-size) {
                  width: 15px !important;
                }
              }

              .a-down{
                svg{
                  stroke: $t-color;
                }

              }

            }

            ul{
              visibility: visible;
              opacity: 1;
              position: relative;
              transform: translateY(0);

              a{
                .title{
                  margin-right: -22px;
                  font-family: 'bakh-reqular';
                }

                &.active{
                  .title{
                    color: $t-color;
                  }
                }
              }
            }
          }


        }

      }

    }

    .fill-profile{
      background-color: $danger;
      padding: 25px 16px;
      border-radius: 5px;

      .title{
        font-size: 12px;
      }

      p{
        font-size: 11px;
        color: #f5f5f5;
        font-weight:300;
      }

      .btn{
        font-size: 11px;
        margin-top: 20px;
      }
    }

  }

}

.n-app-sidebar{

  &.show{
    right: 0;
  }
}

.n-app-main{
  width: calc(100% - 300px);
  margin-right: $side-width;

  @media(max-width: 1000px){
    width: 100%;
    margin-right: 0;
    transition: 0.2s ease;
  }
}
