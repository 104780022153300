@mixin alert-variant($background, $border, $color) {

  color: #eee;
  background: $background;
  border-color:darken($color,10%) ;

  svg {
    fill: $color;
  }

  .title-c{
    color:$color;
  }

  hr {
    border-top-color: darken($color, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }

  box-shadow: none;

  .bell{
      background: $color !important;
      color: #fff;
      text-shadow: 0 0 0 rgba(0,0,0,0.6);
  }

}
