#bw-referrals{

  .referral-link{
    background: #fff;
    color: #6881b4;
    text-transform: capitalize;

    span{
      text-transform: lowercase;
      color: #aab9d7;
      margin: 0 20px;

      @media(max-width: 768px){
        display: block;
        width: 100%;
        margin: 0;
        margin-bottom: 0px;
        word-break: break-all;
        margin-bottom: 6px;
      }
    }
    padding:10px 20px;

    button{
      background: #f5faff;
      color: #7793a1;
      padding: 2px 7px;
      border: none;

      &:hover{
        background: $tree-color;
        color: #fff;
      }
    }

    .m-banner-link{
      text-decoration: underline !important;
    }

  }

  .balance{
    background: $tree-color;
    padding: 15px;
    color :$color-light-panel;
    color: #d2e0fc;
    font-size: 14px;
    @media(max-width: 768px){
      display: block !important;
    }
    span{
      font-weight: 400;
    }
    .b-c{
      width: 33%;
      text-align: center;
      @media(max-width: 768px){
        width: 100%;
      }
      b{
        display: inline-block;
        margin-left: 10px;
      }
    }
    .b-c-r{
      border-right: 1px solid #394c66;
      @media(max-width: 768px){
        border-right: none;
        border-bottom: 1px solid #394c66;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .ref{
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 7px 0px rgba(104, 129, 180, 0.1);
    padding: 15px;
    margin-bottom: 15px;

    .icon{
      width: 50px;
      height: 50px;
      background: #f6f8fb;
      padding: 10px;
      text-align: center;
      border-radius: 50%;
      margin-right: 10px;
      svg{
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        fill: #d1d9e8;
      }
    }

    .info{
      .email{
        color: #8aabbb;
        font-size: 10px;
      }
      .username{
        color: #597fd1;
        font-size: 12px;
      }
    }

    .status{
      font-size: 12px;

      .active{
            color: #ffb93e;
        }
      .inactive{
        color: #525c7a;

      }
    }

  }

  .banner{

    .title{
      font-size: 17px;
      font-weight: 700;
      color: #001f5d;
      width: 103px;
      @media(max-width: 768px){
        width: 100%;
        margin-bottom: 5px;
        font-size: 13px;
        text-align: center;
      }
    }

    .ban{
        box-shadow: 0px 1px 5px 0px rgba(104, 129, 180, 0.3);
        max-width: 100% !important;
        @media(max-width: 768px){
          margin: 0 auto;
        }
    }

    .input{
      background: #fff;
      border-radius:$border-radius;
      box-shadow: 0px 3px 7px 0px rgba(104, 129, 180, 0.1);
      padding: 15px;

      @include clearfix();

      .row{
        @media(max-width: 768px){
          display: block !important;
        }

        .col{
          width: 100%;
        }
      }

      .link{
        width: 100%;
      }
      textarea{
        border: none;
        color: #bfcff1;
        width: 100%;
        height: 60px;

        &:focus{
          box-shadow: none !important;outline: 0 !important;
        }
      }

      .copy{
        display: inline-block;
        background: #ecf2fc;
        color: #a9b7d5;
        border: none;
        cursor: pointer;
        &:hover{
          background: $tree-color;
          color: #fff;
        }
        @media(max-width: 768px){
          display: block !important;
          margin: 10px auto;
          float: none !important;
        }
      }
    }

    margin-bottom: 30px;
  }

}